import React from 'react';
import {Link} from "react-router-dom";

// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
const LinkWrapper = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

export default LinkWrapper;

// function LinkWrapper({to, ...props}) {
//     return (<Link to={to} {...props} />);
// }
// export default LinkWrapper;