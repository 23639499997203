import React from 'react'

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import NoteForm from 'components/note/NoteForm';
import ProgressSubmitButton from 'formik/ProgressSubmitButton';
import NoteFormContainer from "components/note/NoteFormContainer";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {useNoteState, useNoteDispatch, closeNoteDialog, openNoteDialog} from 'components/note/NoteContext';
import {DialogContent} from "@material-ui/core";

export default function NoteDialog() {
    const state = useNoteState();
    const dispatch = useNoteDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        dispatch(closeNoteDialog());
    };

    return (
        <>
            {state.isDialogOpen ? (
                <NoteFormContainer
                    note={state.note}
                    onChanged={handleClose}
                >
                    {({loading}) => {
                        //console.log('isLoading', loading, state.note);
                        return (
                            <React.Fragment>
                                <Dialog open={state.isDialogOpen} onClose={handleClose} fullScreen={fullScreen}>
                                    <DialogContent style={{padding: 0}}>
                                        <NoteForm/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleClose} color="primary">
                                            Cancel
                                        </Button>
                                        <ProgressSubmitButton
                                            loading={loading}
                                            text={"Save"}
                                            variant={'text'}
                                        />
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
                        )
                    }}
                </NoteFormContainer>
            ) : null}
        </>
    );
}
