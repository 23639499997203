import React from 'react'

const StateContext = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'confirmation-dialog/';
const OPEN_DIALOG = PREFIX + 'OPEN_DIALOG';
const CLOSE_DIALOG = PREFIX + 'CLOSE_DIALOG';

const initialState = {
    title: null,
    text: null,
    confirmationFunction: null,
    isOpen: false,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }

    switch (action.type) {
        case OPEN_DIALOG:
            return {
                ...state,
                isOpen: true,
                title: action.payload.title,
                text: action.payload.text,
                confirmationFunction: action.payload.confirmationFunction,
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                isOpen: false,
            };
        default:
            return state;
    }
}

// Action Creators
export function openConfirmationDialog(title = "Confirmation", text = "Are you sure?", confirmationFunction = null) {
    return {type: OPEN_DIALOG, payload: {title, text, confirmationFunction}};
}

export function closeConfirmationDialog() {
    return {type: CLOSE_DIALOG, payload: {}};
}

function ConfirmationDialogProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

function useConfirmationDialogState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useConfirmationDialogState must be used within a ConfirmationDialogProvider')
    }
    return context
}

function useConfirmationDialogDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useConfirmationDialogDispatch must be used within a ConfirmationDialogProvider')
    }
    return context
}

function useConfirmationDialog() {
    return [useConfirmationDialogState(), useConfirmationDialogDispatch()]
}

export {ConfirmationDialogProvider, useConfirmationDialogState, useConfirmationDialogDispatch, useConfirmationDialog}
