import React from 'react';
import Chip from "@material-ui/core/Chip";

export default ({note, prefix='', size = 'medium', ...rest}) => {
    return (
        <Chip
            size={size}
            label={
                <>
                    {note.goalType !== 0 && prefix}
                    {note.goalType === 0 && ("disabled")}
                    {note.goalType === 1 && ("daily")}
                    {note.goalType === 2 && ("weekly")}
                    {note.goalType === 3 && ("monthly")}
                    {note.goalType === 4 && ("yearly")}
                    {note.goalType === 5 && (
                        <>
                            {note.goalEveryNPeriod} per {" "}
                            {note.goalPeriod === 1 && ("day")}
                            {note.goalPeriod === 2 && ("week")}
                            {note.goalPeriod === 3 && ("month")}
                            {note.goalPeriod === 4 && ("year")}
                        </>
                    )}
                    {note.goalType === 6 && (
                        <>
                            1 every{" "}
                            {note.goalEveryNPeriod}{" "}
                            {note.goalPeriod === 1 && ("day(s)")}
                            {note.goalPeriod === 2 && ("week(s)")}
                            {note.goalPeriod === 3 && ("month(s)")}
                            {note.goalPeriod === 4 && ("year(s)")}
                        </>
                    )}
                </>
            }
            {...rest}
        />
    );
}
