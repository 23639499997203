import React from 'react';

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {openCompletedDatesDialog, useCompletedDatesDialogDispatch} from "../utils/CompletedDatesDialogContext";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

export default function CompletedDatesButton({note, size = 'medium', color = 'default'}) {
    const completedDatesDialogDispatch = useCompletedDatesDialogDispatch();

    return (
        <>
            <Tooltip title="Completed Dates">
                <IconButton size={size} color={color} onClick={(e) => {
                    e.stopPropagation();

                    completedDatesDialogDispatch(openCompletedDatesDialog(note));
                }}>
                    <AccessTimeIcon/>
                </IconButton>
            </Tooltip>
        </>
    );
}
