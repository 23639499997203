export const INBOX = 1;
export const TASK = 2;
export const GOAL = 3;
export const EVENT = 4;
export const SOMEDAY = 5;
export const REFERENCE = 6;

const INBOX_TYPE = {
    id: INBOX,
    value: 'Inbox'
};

const TASK_TYPE = {
    id: TASK,
    value: 'Task'
};

const GOAL_TYPE = {
    id: GOAL,
    value: 'Goal'
};

const EVENT_TYPE = {
    id: EVENT,
    value: 'Event & Repeat'
};

const SOMEDAY_TYPE = {
    id: SOMEDAY,
    value: 'Someday'
};

const REFERENCE_TYPE = {
    id: REFERENCE,
    value: 'Reference'
};

// const FUTURE_MAYBE_TYPE = {
//     id: 'FutureMaybe',
//     value: 'Future / Maybe'
// };
//
// const BLOCKED_TYPE = {
//     id: 'Blocked',
//     value: 'Blocked'
// };

export const allTypes = [
    INBOX_TYPE,
    TASK_TYPE,
    EVENT_TYPE,
    GOAL_TYPE,
    SOMEDAY_TYPE,
    // FUTURE_MAYBE,
    // BLOCKED,
    REFERENCE_TYPE,
];
