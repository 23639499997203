import React from 'react'

import {useFormikContext} from "formik";

import Button from '@material-ui/core/Button';

export default function TaskPrioritySelector({priorityName, name, onChange}) {
    const { values, setFieldValue } = useFormikContext();

    return (
        <span style={{display: 'flex', width: '100%'}}>
            <span>{ priorityName }</span>
            <span style={{flex: 1}}/>
            <Button size="small" color={values[name] === 1 ? 'primary' : 'default'}
                    variant={values[name] === 1 ? 'contained' : 'text'} onClick={() => {
                setFieldValue(name, 1);
                if (typeof onChange === 'function') {
                    onChange(1);
                }
            }}>Low</Button>
            <Button size="small" color={values[name] === 2 ? 'primary' : 'default'}
                    variant={values[name] === 2 ? 'contained' : 'text'} onClick={() => {
                setFieldValue(name, 2);
                if (typeof onChange === 'function') {
                    onChange(2);
                }
            }}>Medium</Button>
            <Button size="small" color={values[name] === 3 ? 'primary' : 'default'}
                    variant={values[name] === 3 ? 'contained' : 'text'} onClick={() => {
                setFieldValue(name, 3);
                if (typeof onChange === 'function') {
                    onChange(3);
                }
            }}>High</Button>
        </span>
    )
}
