import React from 'react'

import {Field, useFormikContext} from 'formik';
import {FormMaterialSelectField} from 'formik/FormikMaterialFields';

import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import * as constants from "utils/constants";

export default function EventRepeat() {
    const { values, setFieldValue } = useFormikContext();

    let repeatTypeWidth = 12;
    const eventRepeatType = values.eventRepeatType;

    if (eventRepeatType === constants.EVENT_REPEAT_TYPE_EVERY) {
        repeatTypeWidth = 4;
    }

    return (
        <FormControl style={{width: '100%'}}>
            <Grid container spacing={1}>
                <Grid item xs={repeatTypeWidth}>
                    <Field
                        name="eventRepeatType"
                        component={FormMaterialSelectField}
                        label="Repeat"
                        options={constants.eventRepeatTypeOptions}
                    />
                </Grid>
                {eventRepeatType === constants.EVENT_REPEAT_TYPE_EVERY &&
                <Grid item xs={3}>
                    <Field
                        name="eventRepeatEveryNPeriod"
                        component={FormMaterialSelectField}
                        label=" "
                        options={constants.eventRepeatEveryNPeriodOptions}
                    />
                </Grid>
                }
                {eventRepeatType === constants.EVENT_REPEAT_TYPE_EVERY &&
                <Grid item xs={5}>
                    <Field
                        name="eventRepeatPeriod"
                        component={FormMaterialSelectField}
                        label=" "
                        options={constants.eventRepeatPeriodOptions}
                    />
                </Grid>
                }
            </Grid>
        </FormControl>
    )
}
