import {gql} from "apollo-boost";

// export const CREATE_TAG = gql`
//   mutation CreateTag(
//     $name: String!,
//   ) {
//     createTag(input: {
//       name: $name,
//     }) {
//       tag {
//         id,
//         name,
//       }
//     }
//   }
// `;
//
// export const UPDATE_TAG = gql`
//   mutation UpdateTag(
//     $id: ID!,
//     $name: String!,
//   ) {
//     updateTag(input: {
//       id: $id,
//       name: $name,
//     }) {
//       tag {
//         id,
//         name,
//       }
//     }
//   }
// `;

export const GET_TAGS = gql`
  {
    tags {
      id,
      name,
    }
  }
`;
