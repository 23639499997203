import moment from 'moment';
import v4 from 'uuid';

export class Task {
    constructor() {
        this.completed = false;
        this.text = '';
    }
}

export class CompletedDate {
    constructor() {
        this.completedAt = moment.utc().format();
    }
}

export default class Note {
    constructor() {
        //let currentUtcTime = moment.utc().format();

        //this.id = v4();

        this.title = '';
        this.text = '';
        this.tags = [];
        this.tasks = [
            //new Task(),
        ]; /* array of objects { text: string, completed: bool} */

        this.type = 1;
        this.groupId = null;

        this.size = 1;

        //next tasks
        this.dueDate = null;
        this.urgency = 1;
        this.importance = 1;
        this.significance = 1;

        //event / routine / repeating
        this.eventDate = null;
        this.eventRepeatType = 1; // none, daily, weekly, monthly, yearly, every,
        this.eventRepeatPeriod = 1; // day(s), week(s), month(s), year(s)
        this.eventRepeatEveryNPeriod = 1; // One, two, tree, four, five, six, seven -- 10

        //goal
        this.goalType = 1; // daily, weekly, monthly, yearly, per, every
        this.goalPeriod = 1;  // day, week, month, year
        this.goalEveryNPeriod = 1; // One, two, tree, four, five, six, seven -- 10
        //todo[as]: add: preferred time, day, week, month

        this.completedDates = [];

        // timestamps
        //this.createdAt = null;
        //this.updatedAt = null;
        this.deletedAt = null;
        this.archivedAt = null;
    }
}
