import React, {useState, useEffect} from 'react';
import axios from "axios";
import { Redirect } from 'react-router-dom';

import Container from '@material-ui/core/Container';

import LoginForm from 'components/login/LoginForm';
import useLocalStorage from 'components/utils/useLocalStorage';
import { ENTRYPOINT } from 'config/entrypoint';

function LoginPage() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);
    const [token, setToken] = useLocalStorage('token', null);
    const [redirect, setRedirect] = useState(false);

    console.log('token', token);

    useEffect(() => {
        if (token) {
            setRedirect(true);
        }
    }, [token]);

    if (redirect) {
        console.log('redirect');
        return <Redirect to={'/'} />;
    }

    const onRequestUserLogin = (email, password) => {
        setIsSubmitting(true);
        setShowLoginError(false);

        axios.post(ENTRYPOINT + '/login_check', {
            email: email,
            password: password,
        }).then(function (response) {
            console.log('response', response);
            setToken(response.data.token);
        })
        .catch(function (error) {
            setShowLoginError(true);
        })
        .finally(function() {
            setIsSubmitting(false);
        });
    };

    return (
        <Container component="main" maxWidth="xs">
            <div style={{
                marginTop: 32
            }}>
                <LoginForm onRequestUserLogin={onRequestUserLogin} isSubmitting={isSubmitting}/>
                {showLoginError
                    ?
                    <div>Login error</div>
                    : null
                }
            </div>
        </Container>
    );
}

export default LoginPage;
