import React, {useEffect} from "react";
import has from "lodash/has";
import get from "lodash/get";

import {useGetNotes} from "api/NoteApi";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'notes/';
const FETCH_NOTES_PENDING = PREFIX + 'FETCH_NOTES_PENDING';
const FETCH_NOTES_SUCCESS = PREFIX + 'FETCH_NOTES_SUCCESS';
const FETCH_NOTES_FAILURE = PREFIX + 'FETCH_NOTES_FAILURE';

const initialState = {
    loading: false,
    notes: [],
    errors: [],
    firstTimeLoaded: false,
    hydrated: false,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }

    switch (action.type) {
        case FETCH_NOTES_PENDING:
            return {
                ...state,
                loading: true,
                errors: [],
            };
        case FETCH_NOTES_SUCCESS:
            return {
                ...state,
                notes: action.payload.notes,
                loading: false,
                firstTimeLoaded: true,
            };
        case FETCH_NOTES_FAILURE:
            return {
                ...state,
                errors: action.payload.errors,
                loading: false,
                firstTimeLoaded: true,
            };
        default:
            return state;
    }
}

// Action Creators
export function fetchNotesPending() {
    return {type: FETCH_NOTES_PENDING};
}

export function fetchNotesSuccess(notes) {
    return {type: FETCH_NOTES_SUCCESS, payload: notes};
}

export function fetchNotesFailure(errors) {
    return {type: FETCH_NOTES_FAILURE, payload: errors};
}

// export function getNotesById(state = {}) {
//     return (metadataName = '', id = null) => {
//         if (has(state.metadata, metadataName)) {
//             const foundNotes = state.metadata[metadataName].find((metadataValue) => {
//                 return metadataValue.id === id;
//             });
//
//             if (typeof foundNotes !== 'undefined') {
//                 return foundNotes;
//             }
//         }
//
//         return null;
//     };
// }

function NotesProvider({notes, children}) {
    const [state, dispatch] = React.useReducer(reducer, {
        ...initialState,
        notes,
    });
    console.log('NotesProvider state', state);
    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

function useNotesState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useNotesState must be used within a NotesProvider')
    }
    return context;
}

function useNotesDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useNotesDispatch must be used within a NotesProvider')
    }
    return context;
}

function useNotes() {
    const state = useNotesState();
    const dispatch = useNotesDispatch();

    // const [notes, loading, errors, fetchNotes] = useGetNotes();
    //
    // useEffect(() => {
    //     console.log('notes first time load ', state, state.firstTimeLoaded);
    //     if (!state.firstTimeLoaded && !state.loading) {
    //         console.log('notes dispatch');
    //         dispatch(fetchNotesPending());
    //         fetchNotes();
    //     }
    //     //console.log('test MetaDataContext useEffect');
    //
    // }, []);
    //
    // useEffect(() => {
    //     if (errors) {
    //         dispatch(fetchNotesFailure(errors));
    //     }
    // }, [errors]);
    //
    // useEffect(() => {
    //     if (notes) {
    //         dispatch(fetchNotesSuccess(notes));
    //     }
    // }, [notes]);

    return {
        notes: state.notes,
        loading: state.loading,
        errors: state.errors,
        state,
        dispatch,
    }
}

export {NotesProvider, useNotesState, useNotesDispatch, useNotes}
