

// Actions
const SET = 'my-app/filters/SET';

// Reducer
export default function reducer(state = {}, action = {}) {
    switch (action.type) {
        case SET:
            const key = action.key;
            const value = action.value;

            return {
                ...state,
                [key]: value,
            };
        // do reducer stuff
        default: return state;
    }
}

// Action Creators
export function setFilter(key, value) {
    return { type: SET, key, value };
}

// side effects, only as applicable
// e.g. thunks, epics, etc
// export function getWidget () {
//     return dispatch => get('/widget').then(widget => dispatch(updateWidget(widget)))
// }
