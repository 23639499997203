import React, {useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {Link, Redirect} from 'react-router-dom'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import {useSaveCategory} from "api/CategoryApi";
import {getCategoriesPath} from 'utils/routes';
import {FormMaterialTextField} from "formik/FormikMaterialFields";
import ProgressButton from "utils/ProgressButton";
import ResponseErrors from "utils/ResponseErrors";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CategoryForm({initialValues, isNew}) {
    const [categoryData, setCategoryData] = useState(null);
    const [savedCategory, isSubmitting, categoryErrors, submitCategory] = useSaveCategory(categoryData, isNew);
    const [value, setValue] = React.useState(0);

    const onSaveCategory = (values) => {
        setCategoryData(values);
        submitCategory();
    };

    if (savedCategory) {
        return (<Redirect push to={getCategoriesPath(1)}/>);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    onSaveCategory(values);
                }}
            >
                {({handleSubmit}) => (
                    <Form>
                        <Paper style={{padding: 16, marginBottom: 16}}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Field
                                        name="title"
                                        label="Name"
                                        margin="dense"
                                        fullWidth
                                        nomargin={1}
                                        component={FormMaterialTextField}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <div style={{display: 'flex'}}>
                            <ResponseErrors errors={categoryErrors} />
                            <span style={{flex: 1}} />
                            <ProgressButton
                                loading={isSubmitting}
                                text={"Save"}
                                onClick={handleSubmit}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </React.Fragment>
    );
}

export default CategoryForm;
