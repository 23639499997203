import React, {useMemo} from "react";
import moment from 'moment'
import {isEmpty} from "lodash";

import {getGoalDoEveryNDays, getGoalNextDoDate} from 'utils/notes'
import * as noteTypes from 'utils/noteTypes';
import {transformToFormData} from 'utils/notes';
import {filterByTags} from "utils/filters";
import {useNotes} from "contexts/NotesContext";

// export default function useGoals(tagsFilter = []) {
//     const {loading, error, data} = useQuery(noteGql.GET_NOTES);
//
//     if (loading || error) return [];
//
//     let utcNowDate = moment.utc();
//     let periodDays = 30;
//
//     let prioritizedGoalNotes = data.notes.filter(note => {
//         return note.type === noteTypes.GOAL && note.deletedAt === null && note.archivedAt === null;
//     }).map(transformToFormData).sort(function (a, b) {
//         let aNextDoDate = getGoalNextDoDate(a, periodDays, utcNowDate);
//         let bNextDoDate = getGoalNextDoDate(b, periodDays, utcNowDate);
//
//         console.log('Determining goal priority by date aNextDoDate bNextDoDate' + a.text + ' ' + b.text + ' ' + aNextDoDate + ' ' + bNextDoDate);
//
//         if (aNextDoDate.isBefore(bNextDoDate)) {
//             return -1;
//         }
//
//         if (aNextDoDate.isAfter(bNextDoDate)) {
//             return 1;
//         }
//
//         let aShouldBeDoneEveryNDays = getGoalDoEveryNDays(a);
//         let bShouldBeDoneEveryNDays = getGoalDoEveryNDays(b);
//
//         console.log('Determining goal priority by frequency aShouldBeDoneEveryNDays bShouldBeDoneEveryNDays' + ' ' + aShouldBeDoneEveryNDays + ' ' + bShouldBeDoneEveryNDays, a);
//
//         if (aShouldBeDoneEveryNDays > bShouldBeDoneEveryNDays) {
//             return 1;
//         }
//
//         if (aShouldBeDoneEveryNDays < bShouldBeDoneEveryNDays) {
//             return -1;
//         }
//
//         let aSize = a.size;
//         let bSize = b.size;
//
//         console.log('Determining goal priority by size aSize bSize' + ' ' + aSize + ' ' + bSize);
//
//         if (aSize < bSize) {
//             return 1;
//         }
//
//         if (aSize > bSize) {
//             return -1;
//         }
//
//         return 0;
//     });
//
//     prioritizedGoalNotes = prioritizedGoalNotes.filter(filterByTags(tagsFilter));
//
//     return prioritizedGoalNotes.slice(0, 15);
// }

export default function useGoals(tagsFilter = []) {
    const { notes, loading, errors } = useNotes();

    return useMemo(() => {
        if (!notes || loading || !isEmpty(errors)) return [];

        let utcNowDate = moment.utc();
        let periodDays = 30;

        let prioritizedGoalNotes = notes.filter(note => {
            return note.type === noteTypes.GOAL && note.archivedAt === null;
        }).sort(function (a, b) {
            let aNextDoDate = getGoalNextDoDate(a, periodDays, utcNowDate);
            let bNextDoDate = getGoalNextDoDate(b, periodDays, utcNowDate);

            console.log('Determining goal priority by date aNextDoDate bNextDoDate' + a.text + ' ' + b.text + ' ' + aNextDoDate + ' ' + bNextDoDate);

            if (aNextDoDate.isBefore(bNextDoDate)) {
                return -1;
            }

            if (aNextDoDate.isAfter(bNextDoDate)) {
                return 1;
            }

            let aShouldBeDoneEveryNDays = getGoalDoEveryNDays(a);
            let bShouldBeDoneEveryNDays = getGoalDoEveryNDays(b);

            console.log('Determining goal priority by frequency aShouldBeDoneEveryNDays bShouldBeDoneEveryNDays' + ' ' + aShouldBeDoneEveryNDays + ' ' + bShouldBeDoneEveryNDays, a);

            if (aShouldBeDoneEveryNDays > bShouldBeDoneEveryNDays) {
                return 1;
            }

            if (aShouldBeDoneEveryNDays < bShouldBeDoneEveryNDays) {
                return -1;
            }

            let aSize = a.size;
            let bSize = b.size;

            console.log('Determining goal priority by size aSize bSize' + ' ' + aSize + ' ' + bSize);

            if (aSize < bSize) {
                return 1;
            }

            if (aSize > bSize) {
                return -1;
            }

            return 0;
        });

        prioritizedGoalNotes = prioritizedGoalNotes.filter(filterByTags(tagsFilter));

        return prioritizedGoalNotes.slice(0, 15);
    }, [notes, loading, errors]);
};


//
// export const getGoalsBySelectedGroup = createSelector(
//     getNotesById,
//     getAllNotes,
//     selectedGroupId,
//     (notesById, allNotes, selectedGroupId) => {
//         let utcNowDate = moment.utc();
//         let periodDays = 7;
//
//         let prioritizedGoalNotes = allNotes.filter(id => {
//             return notesById[id].typeId === noteTypes.GOAL && notesById[id].deleted === null && notesById[id].archived === null && notesById[id].groupId === selectedGroupId;
//         }).sort(function (a, b) {
//             let aNextDoDate = getGoalNextDoDate(notesById[a], periodDays, utcNowDate);
//             let bNextDoDate = getGoalNextDoDate(notesById[b], periodDays, utcNowDate);
//
//             console.log('Determining goal priority by date aNextDoDate bNextDoDate' + notesById[a].text + ' ' + notesById[b].text + ' ' + aNextDoDate + ' ' + bNextDoDate);
//
//             if (aNextDoDate.isBefore(bNextDoDate)) {
//                 return -1;
//             }
//
//             if (aNextDoDate.isAfter(bNextDoDate)) {
//                 return 1;
//             }
//
//             let aShouldBeDoneEveryNDays = getGoalDoEveryNDays(notesById[a]);
//             let bShouldBeDoneEveryNDays = getGoalDoEveryNDays(notesById[b]);
//
//             console.log('Determining goal priority by frequency aShouldBeDoneEveryNDays bShouldBeDoneEveryNDays' + ' ' + aShouldBeDoneEveryNDays + ' ' + bShouldBeDoneEveryNDays, notesById[a]);
//
//             if (aShouldBeDoneEveryNDays > bShouldBeDoneEveryNDays) {
//                 return 1;
//             }
//
//             if (aShouldBeDoneEveryNDays < bShouldBeDoneEveryNDays) {
//                 return -1;
//             }
//
//             let aSize = notesById[a].size;
//             let bSize = notesById[b].size;
//
//             console.log('Determining goal priority by size aSize bSize' + ' ' + aSize + ' ' + bSize);
//
//             if (aSize < bSize) {
//                 return 1;
//             }
//
//             if (aSize > bSize) {
//                 return -1;
//             }
//
//             return 0;
//         });
//
//         return prioritizedGoalNotes.map(id => {
//             return notesById[id];
//         });
//     }
// );
