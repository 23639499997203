import React from 'react';

import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";

import {useNoteDispatch, openNoteDialog} from 'components/note/NoteContext';

export default function NewNoteIconButton() {
  const dispatch = useNoteDispatch();

  const handleClickOpen = () => {
    dispatch(openNoteDialog(null));
  };

  return (
    <>
      <Tooltip title="New note" onClick={handleClickOpen}>
        <IconButton color="inherit">
          <AddIcon fontSize="large" />
        </IconButton>
      </Tooltip>
    </>
  );
}
