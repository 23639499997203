import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Tooltip from '@material-ui/core/Tooltip';

import {useConfirmationDialogState, useConfirmationDialogDispatch, closeConfirmationDialog} from 'components/utils/ConfirmationDialogContext';

function ConfirmationDialog({dialogTitle = "Confirmation", dialogContent = "Are you sure?", confirmationFunction}) {
    const confirmationDialogState = useConfirmationDialogState();
    const confirmationDialogDispatch = useConfirmationDialogDispatch();

    console.log('confirmationDialogState', confirmationDialogState);


    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={confirmationDialogState.isOpen}
                onClose={() => confirmationDialogDispatch(closeConfirmationDialog())}
            >
                <DialogTitle>{confirmationDialogState.title}</DialogTitle>
                <DialogContent>
                    {dialogContent}
                </DialogContent>
                <DialogActions style={{display: 'flex'}}>
                    <Button onClick={() => confirmationDialogDispatch(closeConfirmationDialog())} color="primary">
                        Cancel
                    </Button>
                    <span style={{flex: 1}}/>
                    <Button onClick={() => {
                        if (typeof confirmationDialogState.confirmationFunction === 'function') {
                            confirmationDialogState.confirmationFunction();
                        }

                        confirmationDialogDispatch(closeConfirmationDialog());
                    }} color="primary">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default ConfirmationDialog;
