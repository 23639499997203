import React, {useState} from 'react';
import {Field, useFormikContext} from 'formik';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import TaskPrioritySelector from './TaskPrioritySelector';

export default function TaskPriorities() {
    const { values, setFieldValue } = useFormikContext();
    const [visualHelperIndex, setVisualHelperIndex] = useState(0);
    const [isPriorityHelperOpen, setIsPriorityHelperOpen] = useState(true);

    console.log('visualHelperIndex', visualHelperIndex);

    return (
        <>
            <FormControl style={{width: '100%', textAlign: 'center'}}>
                <InputLabel shrink={true}>Priority</InputLabel>
                {/*<Button size="small" style={{*/}
                {/*    position: 'absolute',*/}
                {/*    right: 0,*/}
                {/*    top: -3,*/}
                {/*    padding: '2px 8px',*/}
                {/*    minHeight: 20*/}
                {/*}} onClick={() => setIsPriorityHelperOpen(!isPriorityHelperOpen)} dense="true">*/}
                {/*    Helper*/}
                {/*</Button>*/}
                <div style={{display: 'inline'}}>
                    <Button size="small"
                            style={{minWidth: 40}}
                            color={visualHelperIndex === 1 ? 'primary' : 'default'}
                            variant={visualHelperIndex === 1 ? 'contained' : 'text'}
                            onClick={() => {
                                setFieldValue('urgency', 1);
                                setFieldValue('importance', 1);
                                setFieldValue('significance', 1);
                                setVisualHelperIndex(1);
                            }}>
                        Low
                    </Button>
                    <Button size="small"
                            style={{minWidth: 40}}
                            color={visualHelperIndex === 2 ? 'primary' : 'default'}
                            variant={visualHelperIndex === 2 ? 'contained' : 'text'}
                            onClick={() => {
                                setFieldValue('urgency', 2);
                                setFieldValue('importance', 2);
                                setFieldValue('significance', 2);
                                setVisualHelperIndex(2);
                            }}>
                        Mid
                    </Button>
                    <Button size="small"
                            style={{minWidth: 40}}
                            color={visualHelperIndex === 3 ? 'primary' : 'default'}
                            variant={visualHelperIndex === 3 ? 'contained' : 'text'}
                            onClick={() => {
                                setFieldValue('urgency', 3);
                                setFieldValue('importance', 3);
                                setFieldValue('significance', 3);
                                setVisualHelperIndex(3);
                            }}>
                        High
                    </Button>
                </div>
            </FormControl>
            <Collapse in={isPriorityHelperOpen} timeout="auto" unmountOnExit>
                <Typography paragraph style={{marginTop: 16}}>
                    <TaskPrioritySelector name="urgency" priorityName="Urgency" onChange={() => setVisualHelperIndex(0)}/>
                </Typography>
                <Typography paragraph>
                    <TaskPrioritySelector name="importance" priorityName="Importance" onChange={() => setVisualHelperIndex(0)}/>
                </Typography>
                <Typography paragraph>
                    <TaskPrioritySelector name="significance" priorityName="Significance" onChange={() => setVisualHelperIndex(0)}/>
                </Typography>
            </Collapse>
        </>
    );
}
