import { useState, useCallback, useLayoutEffect } from "react";

function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: "x" in rect ? rect.x : rect.top,
        left: "y" in rect ? rect.y : rect.left,
        x: "x" in rect ? rect.x : rect.left,
        y: "y" in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom
    };
}

function useDimensions({liveMeasure = true}) {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState(null);

    const ref = useCallback(node => {
        setNode(node);
    }, []);

    const forceRecalculate = () => {
        if (node) {
            measure(node);
        }
    };

    const measure = (node) => {
        window.requestAnimationFrame(() =>
            setDimensions(getDimensionObject(node))
        );
    };

    useLayoutEffect(() => {
        if (node) {
            measure(node);

            if (liveMeasure) {
                window.addEventListener("resize", measure);
                window.addEventListener("scroll", measure);

                return () => {
                    window.removeEventListener("resize", measure);
                    window.removeEventListener("scroll", measure);
                };
            }
        }
    }, [node]);

    return [ref, dimensions, node, forceRecalculate];
}

export default useDimensions;