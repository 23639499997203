import {useMakeRequest} from './Api';
import { ENTRYPOINT } from 'config/entrypoint';

const API_NOTES_URL = '/notes';

export function useGetNotes() {
    const { data, isLoading, errors, doRequest } = useMakeRequest('get', ENTRYPOINT + API_NOTES_URL);

    return [ data, isLoading, errors, doRequest ];
}

export function useUpsertNote(noteData) {
    const { data, isLoading, errors, doRequest } = useMakeRequest('put', ENTRYPOINT + API_NOTES_URL, noteData);

    return [ data, isLoading, errors, doRequest ];
}

export function useGetGoalsTree() {
    const { data, isLoading, errors, doRequest } = useMakeRequest('get', ENTRYPOINT + '/goals-tree/7');

    return [ data, isLoading, errors, doRequest ];
}

export function useGetGoalsSchedule() {
    const { data, isLoading, errors, doRequest } = useMakeRequest('get', ENTRYPOINT + '/goals-schedule');

    return [ data, isLoading, errors, doRequest ];
}

export function useSaveGoalsTree(goalsTreeData) {
    const data = {
        goals: goalsTreeData,
    };
    const { doRequest } =  useMakeRequest('put', ENTRYPOINT + '/goals-tree', data);

    return [ doRequest ];
}

export function useCompleteNote(noteId, dataToSend) {
    const { data, isLoading, doRequest } =  useMakeRequest('put', `${ENTRYPOINT}/notes/${noteId}/complete`, dataToSend);

    return [ data, isLoading, doRequest ];
}


export function useDeleteCompletedDate(noteId, completedDateId) {
    const { data, isLoading, doRequest } =  useMakeRequest('delete', `${ENTRYPOINT}/notes/${noteId}/completedDates/${completedDateId}`);

    return [ data, isLoading, doRequest ];
}
