import React from 'react';
import {Redirect, Route} from "react-router-dom";
import useLocalStorage from "components/utils/useLocalStorage";

function RouteAuthenticated({ component: Component, ...rest }) {
    const [token, setToken] = useLocalStorage('token', null);

    return (
        <Route
            {...rest}
            render={props =>
                token ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
}

export default RouteAuthenticated;
