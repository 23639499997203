import React from 'react'
import moment from 'moment';

import {Field, useFormikContext} from 'formik';
import {FormDatePickerField} from 'formik/FormikMaterialFields';

import {convertFormDateToUtc, convertUtcToFormDate} from 'utils/form';

export default function TaskDueDate({priorityName, name, onChange}) {
    const { values, setFieldValue } = useFormikContext();
    //console.log('TaskDueDate values', values['dueDate'], convertFormDateToUtc(values['dueDate']), moment(values['dueDate']).format(), moment(values['dueDate']).utc().format(), moment(values['dueDate']).utc().local().format());
    return (
        <Field
            name="dueDate"
            // value={values['dueDate'] = values['dueDate'] === null ? null : convertFormDateToUtc(values['dueDate'])}
            component={FormDatePickerField}
            // onChange={(date) => {
            //     console.log('TaskDueDate onchange', date, convertUtcToFormDate(date));
            //     setFieldValue('dueDate', convertUtcToFormDate(date));
            // }}
            label="Due Date"/>
    )
}
