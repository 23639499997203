import qs from "qs";
import axios from "axios";
import {getAuthTokenHeaders, useMakeRequest} from './Api';
import { ENTRYPOINT } from 'config/entrypoint';

const API_CATEGORIES_URL = '/categories';
const API_CATEGORIES_TREE_URL = '/categories-tree';

export function useGetCategories(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', ENTRYPOINT + API_CATEGORIES_URL + queryString);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useSaveCategory(categoryData, isNew) {
    const method = isNew ? 'post' : 'put';
    const url = isNew ? '' : '/' + (categoryData ? categoryData.id : '');
    const { data, isLoading, errors, doRequest } =  useMakeRequest(method, ENTRYPOINT + API_CATEGORIES_URL + url, categoryData);

    return [ data, isLoading, errors, doRequest ];
}

export function useGetCategoriesTree() {
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest('get', ENTRYPOINT + API_CATEGORIES_TREE_URL);

    return [ data, totalCount, isLoading, errors, doRequest ];
}


export function useSaveCategoriesTree(categoriesTreeData) {
    const method = 'put';
    const data = {
        categories: categoriesTreeData,
    };
    const { doRequest } =  useMakeRequest(method, ENTRYPOINT + API_CATEGORIES_TREE_URL, data);

    return [ doRequest ];
}

export function getCategories(perPage, page) {
    const queryParams = {
        'per_page': perPage,
        'page': page,
    };

    const queryString = '?' + qs.stringify(queryParams);
    const authTokenHeaders = getAuthTokenHeaders();

    return axios.get(
        ENTRYPOINT + API_CATEGORIES_URL + queryString,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function getCategory(categoryId) {
    const authTokenHeaders = getAuthTokenHeaders();
    return axios.get(
        ENTRYPOINT + API_CATEGORIES_URL + '/' + categoryId,
        {
            headers: {...authTokenHeaders}
        }
    ).then((response) => {
        return response.data;
    });
}

export function saveCategory(isNew, category) {
    let method = 'post';
    let url = ENTRYPOINT + API_CATEGORIES_URL;
    const authTokenHeaders = getAuthTokenHeaders();

    if (!isNew) {
        method = 'put';
        url = url + '/' + category.id;
    }

    return axios.request({
        url: url,
        method: method,
        headers: {...authTokenHeaders},
        data: {...category},
    }).then((response) => {
        return response.data;
    });
}
