import moment from 'moment';
import {
    isArray,
    isEmpty,
    first,
    get,
} from 'lodash';

import * as constants from "utils/constants";

export const getNotePriority = (note) => {
    let basePriority = note.urgency * 0.35 + note.importance * 0.35 + note.significance * 0.3;

    if (note.dueDate !== null) {
        let sizeMultiplicator = 1;
        if (note.size === 3) {
            sizeMultiplicator = 2;
        } else if (note.size === 2) {
            sizeMultiplicator = 1.5;
        }

        let daysLeft = moment(note.dueDate).diff(moment(), 'days', true);

        let addsPriority = 3;
        if (daysLeft > 0) {
            addsPriority = (3.10714 - (0.482143 * daysLeft)) * sizeMultiplicator;

            if (addsPriority < 0.1) {
                addsPriority = 0.1;
            }
        }

        let adjustedPriority = (basePriority + addsPriority);

        return adjustedPriority > 3 ? 3.1 : adjustedPriority.toFixed(2);
    } else {
        return basePriority.toFixed(2);
    }
};

export const getGoalNextDoDate = (note, periodDays, utcNowDate) => {
    utcNowDate = (typeof utcNowDate === 'undefined') ? moment.utc() : utcNowDate;

    // reverse completed dates and find the first date which starts a period
    let firstPeriodDay = utcNowDate;
    let goalsDoneInPeriod = 0;

    // get when was the first time the goal was done in the period and all goals done in the period
    //for (let index = (note.completedDates.length - 1); index >= 0; index--) {
    for (let index = 0; index < note.completedDates.length; index++) {
        let completedDate = moment.utc(note.completedDates[index].completedAt);
        if (utcNowDate.clone().subtract(periodDays, "days").isBefore(completedDate)) {
            firstPeriodDay = completedDate;
            goalsDoneInPeriod++;
        } else {
            break;
        }
    }

    console.log('first period day and goal done in period', firstPeriodDay, goalsDoneInPeriod, utcNowDate);
    if (goalsDoneInPeriod === 0) {
        return utcNowDate;
    }

    // note.goalType === 'every' ? note.goalEveryNPeriod : (getDaysByRoutinePeriod(note.goalPeriod) / note.goalTimesInPeriod);
    let doneEveryNDays = getGoalDoEveryNDays(note);
    console.log('goal doneEveryNDays', doneEveryNDays);

    let shouldBeDoneInPeriod = periodDays / doneEveryNDays;
    let latestDone = moment.utc(note.completedDates[0].completedAt);
    let nextDoDate;

    console.log('goalsDoneInPeriod shouldBeDoneInPeriod firstPeriodDay', goalsDoneInPeriod, shouldBeDoneInPeriod, firstPeriodDay.format());
    // done more that should in the period
    if (goalsDoneInPeriod >= shouldBeDoneInPeriod) {
        // last time done was very recently, so it should be at least doneEveryNDays days apart
        // and should be in the future?

        nextDoDate = firstPeriodDay.clone().add(periodDays, "days");
        // nextDoDate = 06-21      || 06-25
        // latestDone = 06-20
    } else {
        // if behind the goal, recommended day shouldn't be lower than last done
        nextDoDate = firstPeriodDay.clone().add(goalsDoneInPeriod * doneEveryNDays, "days");
    }

    // in both cases above, need to check this
    // if behind the goal, recommended day shouldn't be lower than last done
    if (nextDoDate.clone().subtract(doneEveryNDays, "days").isBefore(latestDone)) {
        nextDoDate = latestDone.clone().add(doneEveryNDays, 'days');
    }

    return nextDoDate;
};

export const getSortedGoals = (goals, periodDays) => {
    return goals.map((goal) => {
        // if Goal has children, then it's nextDoDate depends on the nextDoDate of a child Goal
        if (isArray(goal.children) && !isEmpty(goal.children)) {
            goal.children = getSortedGoals(goal.children, periodDays);

            goal.lastDone = first(goal.children).lastDone;
            goal.nextDoDate = first(goal.children).nextDoDate;
        } else {
            goal.lastDone = get(goal, 'completedDates.0.completedAt', null);
            goal.nextDoDate = getGoalNextDoDate(goal, periodDays);
        }

        return goal;
    }).sort(sortGoals);
};

export const sortGoals = (a, b) => {
    let aNextDoDate = a.nextDoDate;
    let bNextDoDate = b.nextDoDate;

    console.log('Determining goal priority by date aNextDoDate bNextDoDate' + a.text + ' ' + b.text + ' ' + aNextDoDate + ' ' + bNextDoDate);

    if (aNextDoDate.isBefore(bNextDoDate)) {
        return -1;
    }

    if (aNextDoDate.isAfter(bNextDoDate)) {
        return 1;
    }

    let aShouldBeDoneEveryNDays = getGoalDoEveryNDays(a);
    let bShouldBeDoneEveryNDays = getGoalDoEveryNDays(b);

    console.log('Determining goal priority by frequency aShouldBeDoneEveryNDays bShouldBeDoneEveryNDays' + ' ' + aShouldBeDoneEveryNDays + ' ' + bShouldBeDoneEveryNDays, a);

    if (aShouldBeDoneEveryNDays > bShouldBeDoneEveryNDays) {
        return 1;
    }

    if (aShouldBeDoneEveryNDays < bShouldBeDoneEveryNDays) {
        return -1;
    }

    let aSize = a.size;
    let bSize = b.size;

    console.log('Determining goal priority by size aSize bSize' + ' ' + aSize + ' ' + bSize);

    if (aSize < bSize) {
        return 1;
    }

    if (aSize > bSize) {
        return -1;
    }

    return 0;
};

export const getGoalDoEveryNDays = (note) => {
    //this.goalType = null; // daily, weekly, monthly, yearly, per, every
    //this.goalPeriod = null;  // day, week, month, year
    //this.goalEveryNPeriod = 1; // One, two, tree, four, five, six, seven -- 10

    if (note.goalType === constants.GOAL_TYPE_DAILY) {
        return 1;
    } else if (note.goalType === constants.GOAL_TYPE_WEEKLY) {
        return 7;
    } else if (note.goalType === constants.GOAL_TYPE_MONTHLY) {
        //todo[as]: smarter
        return 30;
    } else if (note.goalType === constants.GOAL_TYPE_YEARLY) {
        //todo[as]: smarter
        return 365;
    } else if (note.goalType === constants.GOAL_TYPE_PER) {
        return getDaysByRoutinePeriod(note.goalPeriod) / note.goalEveryNPeriod;
    } else if (note.goalType === constants.GOAL_TYPE_EVERY) {
        return  note.goalEveryNPeriod * getDaysByRoutinePeriod(note.goalPeriod);
    }

    console.error('getGoalDoEveryNDays: should never be reached');
};

//todo[as]: do the same as the one below, without mutation
export const convertNoteFormDatesToUtc = (note) => {
    console.log('convertNoteFormDatesToUtc', note);
    if (note.eventDate !== '') {
        note.eventDate = moment(note.eventDate).utc().format();
    }

    if (note.dueDate !== '') {
        note.dueDate = moment(note.dueDate).utc().format();
    }

    note.completedDates.forEach((completedDate, index, completedDates) => {
        completedDates[index].completedAt = moment(completedDate.completedAt).utc().format();
    });

    console.log('convertNoteFormDatesToUtc after', note);

    return note;
};

export const convertNoteUtcDatesToFormDate= (note) => {
    console.log('convertNoteUtcDatesToFormDate', note);

    let newNote = {};

    if (note.eventDate !== '') {
        newNote.eventDate = moment.utc(note.eventDate).local().format('YYYY-MM-DDTHH:mm');
    }

    if (note.dueDate !== '') {
        newNote.dueDate = moment.utc(note.dueDate).local().format('YYYY-MM-DD');
    }

    if (note.completedDates.length) {
        newNote.completedDates = [];
        note.completedDates.forEach((date) => {
            newNote.completedDates.push(moment.utc(date.completedAt).local().format('YYYY-MM-DDTHH:mm'));
        });
    }

    console.log('convertNoteUtcDatesToFormDate rewrite with', newNote);

    return {
        ...note,
        ...newNote
    };
};

export const getNewEventDate = (note) => {
    if (note.eventRepeatType === constants.EVENT_REPEAT_TYPE_DAILY) {
        return moment.utc(note.eventDate).add(1, 'days').format();
    } else if (note.eventRepeatType === constants.EVENT_REPEAT_TYPE_WEEKLY) {
        return moment.utc(note.eventDate).add(1, 'weeks').format();
    } else if (note.eventRepeatType === constants.EVENT_REPEAT_TYPE_MONTHLY) {
        return moment.utc(note.eventDate).add(1, 'months').format();
    } else if (note.eventRepeatType === constants.EVENT_REPEAT_TYPE_YEARLY) {
        return moment.utc(note.eventDate).add(1, 'years').format();
    } else if (note.eventRepeatType === constants.EVENT_REPEAT_TYPE_EVERY) {
        return moment.utc(note.eventDate).add(note.eventRepeatEveryNPeriod, constants.eventRepeatPeriodOptionToTextMap[note.eventRepeatPeriod]).format();
    }

    console.error('getNewEventDate! shouldn\'t be reached');
};

export const isAllNoteTasksCompleted = (note) => {
    let allCompleted = true;

    if (note.tasks.length) {
        note.tasks.forEach(function(task, index) {
            if (!task.completed) {
                allCompleted = false;
            }
        });
    } else {
        allCompleted = false;
    }

    return allCompleted;
};

export const resetNoteTasks = (note) => {
    note.tasks.forEach(function(task, index, array) {
        array[index].completed = false;
    });

    return note;
};

export const getDaysBeforeEventText = (event) => {
    let daysLeft = moment(event.eventDate).startOf('day').diff(moment(Date.now()).startOf('day'), 'days');

    if (daysLeft > 9) {
        daysLeft = '9+'
    } else if (daysLeft === 0) {
        daysLeft = '!';
    } else if (daysLeft < 0) {
        daysLeft = '!!!';
    }

    return daysLeft;
};

export function transformToFormData(note) {
    let newTasks = [];
    let newTags = [];
    let newCompletedDates = [];

    try {
        const parsed = JSON.parse(note.tasks);

        if (isArray(parsed)) {
            newTasks = parsed;
        }
    } catch(e) {
    }

    try {
        const parsed = JSON.parse(note.tags);

        if (isArray(parsed)) {
            newTags = parsed;
        }
    } catch(e) {
    }

    try {
        const parsed = JSON.parse(note.completedDates);

        if (isArray(parsed)) {
            newCompletedDates = parsed;
        }
    } catch(e) {
    }

    return {
        ...note,
        tasks: newTasks,
        tags: newTags,
        completedDates: newCompletedDates,
    };
}

//todo[as]: smarter
function getDaysByRoutinePeriod(period) {
    switch (period) {
        case constants.GOAL_PERIOD_DAY:
            return 1;
        case constants.GOAL_PERIOD_WEEK:
            return 7;
        case constants.GOAL_PERIOD_MONTH:
            //return 30.4375;
            return 30;
        case constants.GOAL_PERIOD_YEAR:
            //return 365.25;
            return 365;
        default:
            return 1;
    }
}
