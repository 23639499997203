import React from 'react';

import Button from "@material-ui/core/Button";

import {useTheme} from "@material-ui/core/styles";
import LinkWrapper from "utils/LinkWrapper";
import PageTitle from 'utils/PageTitle';

function PageTitleWithButton({pageTitle, buttonTitle, buttonRoute, gutterBottom = false, ...props}) {
    const theme = useTheme();
    const gutterBottomMargin = gutterBottom ? theme.spacing(1) : 0;

    return (
        <div style={{display: 'flex', marginBottom: gutterBottomMargin, width: '100%'}}>
            <PageTitle title={pageTitle}/>
            <span style={{flex: 1}} />
            <Button to={buttonRoute} variant="outlined" color="primary" component={LinkWrapper}>
                {buttonTitle}
            </Button>
        </div>
    );
}
export default PageTitleWithButton;
