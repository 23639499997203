import React from 'react'

const StateContext = React.createContext();
const DispatchContext = React.createContext();

// Actions
const PREFIX = 'completed-dates-dialog/';
const OPEN_DIALOG = PREFIX + 'OPEN_DIALOG';
const CLOSE_DIALOG = PREFIX + 'CLOSE_DIALOG';
const UPDATE_NOTE = PREFIX + 'UPDATE_NOTE';

const initialState = {
    isOpen: false,
    note: null,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }

    switch (action.type) {
        case OPEN_DIALOG:
            return {
                ...state,
                isOpen: true,
                note: action.payload.note,
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                isOpen: false,
            };
        case UPDATE_NOTE:
            return {
                ...state,
                note: action.payload.newNote,
            };
        default:
            return state;
    }
}

// Action Creators
export function openCompletedDatesDialog(note) {
    return {type: OPEN_DIALOG, payload: {note}};
}

export function closeCompletedDatesDialog() {
    return {type: CLOSE_DIALOG, payload: {}};
}

export function updateNote(newNote) {
    return {type: UPDATE_NOTE, payload: { newNote }};
}

function CompletedDatesDialogProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    return (
        <StateContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </StateContext.Provider>
    )
}

function useCompletedDatesDialogState() {
    const context = React.useContext(StateContext);
    if (context === undefined) {
        throw new Error('useCompletedDatesDialogState must be used within a CompletedDatesDialogProvider')
    }
    return context
}

function useCompletedDatesDialogDispatch() {
    const context = React.useContext(DispatchContext);
    if (context === undefined) {
        throw new Error('useCompletedDatesDialogDispatch must be used within a CompletedDatesDialogProvider')
    }
    return context
}

function useCompletedDatesDialog() {
    return [useCompletedDatesDialogState(), useCompletedDatesDialogDispatch()]
}

export {CompletedDatesDialogProvider, useCompletedDatesDialogState, useCompletedDatesDialogDispatch, useCompletedDatesDialog}
