import React, {useMemo} from "react";

import {isEmpty} from 'lodash';

import * as noteTypes from 'utils/noteTypes';
import {transformToFormData} from 'utils/notes';
import {useNotes} from "contexts/NotesContext";

// export default function useInboxNotes(tagsFilter = []) {
//     const {loading, error, data} = useQuery(noteGql.GET_NOTES);
//
//     if (loading || error) return [];
//
//     let notes = data.notes
//         .filter(note => note.type === noteTypes.INBOX && note.deletedAt === null).map(transformToFormData).reverse();
//
//     notes = notes.filter(filterByTags(tagsFilter));
//
//     return notes;
// };

export default function useInboxNotes(tagsFilter = []) {
    const { notes, loading, errors } = useNotes();

    return useMemo(() => {
        if (!notes || loading || !isEmpty(errors)) return [];

        return notes
            .filter(note => note.type === noteTypes.INBOX).reverse();
    }, [notes, loading, errors]);
};

