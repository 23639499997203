import React from 'react';
import CategoryForm from 'pages/Categories/CategoryForm'
// import LinkBack from "utils/LinkBack";
import {getCategoriesPath} from "utils/routes";
import PageTitle from "utils/PageTitle";
import FillHeightContainer from "utils/FillHeightContainer";

function CategoryAdd() {
    return (
        <FillHeightContainer header={
            <>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: 54,
                    padding: '0 16px',
                }}>
                    {/*<LinkBack to={getCategoriesPath()} text={"Atgal"}/>*/}
                    <PageTitle title={"Nauja prekių kategorija"} gutterBottom/>
                    <span style={{flex: 1}} />
                    {/*<ProgressButton*/}
                    {/*    loading={isSubmitting}*/}
                    {/*    text={"Išsaugoti"}*/}
                    {/*    onClick={(e) => {*/}
                    {/*        handleSubmitMyForm(e);*/}
                    {/*    }}*/}
                    {/*    color="primary"*/}
                    {/*/>*/}
                </div>
            </>
        }>
            <CategoryForm
                isNew={true}
                initialValues={{
                    title: '',
                    description: '',
                }}
            />
        </FillHeightContainer>
    );
}

export default CategoryAdd;
