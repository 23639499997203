import {gql} from "apollo-boost";

export const CREATE_NOTE = gql`
  mutation CreateNote(
    $title: String!,
    $type: Int!,
    $size: Float!,
    $dueDate: String,
    $urgency: Int!,
    $importance: Int!,
    $significance: Int!,
    $eventDate: String,
    $eventRepeatType: Int!,
    $eventRepeatPeriod: Int!,
    $eventRepeatEveryNPeriod: Int!,
    $goalType: Int!,
    $goalPeriod: Int!,
    $goalEveryNPeriod: Int!,
    $tasks: String!,
    $tags: String!,
    $completedDates: String!,
  ) {
    createNote(input: {
      title: $title,
      type: $type,
      size: $size,
      dueDate: $dueDate,
      urgency: $urgency,
      importance: $importance,
      significance: $significance,
      eventDate: $eventDate,
      eventRepeatType: $eventRepeatType,
      eventRepeatPeriod: $eventRepeatPeriod,
      eventRepeatEveryNPeriod: $eventRepeatEveryNPeriod,
      goalType: $goalType,
      goalPeriod: $goalPeriod,
      goalEveryNPeriod: $goalEveryNPeriod,
      tasks: $tasks,
      tags: $tags,
      completedDates: $completedDates,
    }) {
      note {
        id,
        title,
        type,
        size,
        dueDate,
        urgency,
        importance,
        significance,
        eventDate,
        eventRepeatType,
        eventRepeatPeriod,
        eventRepeatEveryNPeriod,
        goalType,
        goalPeriod,
        goalEveryNPeriod,
        archivedAt,
        deletedAt,
        tasks,
        tags,
        completedDates,
      }
    }
  }
`;

// export const CREATE_TASK = gql`
//   mutation CreateTask(
//     $text: String!,
//     $completed: Boolean!,
//   ) {
//     createTask(input: {
//       text: $text,
//       completed: $completed,
//     }) {
//       task {
//         id,
//         text,
//         completed,
//       }
//     }
//   }
// `;

export const UPDATE_NOTE = gql`
  mutation UpdateNote(
    $id: ID!,
    $title: String!,
    $type: Int!,
    $size: Float!,
    $dueDate: String,
    $urgency: Int!,
    $importance: Int!,
    $significance: Int!,
    $eventDate: String,
    $eventRepeatType: Int!,
    $eventRepeatPeriod: Int!,
    $eventRepeatEveryNPeriod: Int!,
    $goalType: Int!,
    $goalPeriod: Int!,
    $goalEveryNPeriod: Int!,
    $archivedAt: String,
    $deletedAt: String,
    $tasks: String!,
    $tags: String!,
    $completedDates: String!,
  ) {
    updateNote(input: {
      id: $id,
      title: $title,
      type: $type,
      size: $size,
      dueDate: $dueDate,
      urgency: $urgency,
      importance: $importance,
      significance: $significance,
      eventDate: $eventDate,
      eventRepeatType: $eventRepeatType,
      eventRepeatPeriod: $eventRepeatPeriod,
      eventRepeatEveryNPeriod: $eventRepeatEveryNPeriod,
      goalType: $goalType,
      goalPeriod: $goalPeriod,
      goalEveryNPeriod: $goalEveryNPeriod,
      archivedAt: $archivedAt,
      deletedAt: $deletedAt,
      tasks: $tasks,
      tags: $tags,
      completedDates: $completedDates,
    }) {
      note {
        id,
        title,
        type,
        size,
        dueDate,
        urgency,
        importance,
        significance,
        eventDate,
        eventRepeatType,
        eventRepeatPeriod,
        eventRepeatEveryNPeriod,
        goalType,
        goalPeriod,
        goalEveryNPeriod,
        archivedAt,
        deletedAt,
        tasks,
        tags,
        completedDates,
      }
    }
  }
`;

export const GET_NOTES = gql`
  {
    notes {
      id,
      title,
      type,
      size,
      dueDate,
      urgency,
      importance,
      significance,
      eventDate,
      eventRepeatType,
      eventRepeatPeriod,
      eventRepeatEveryNPeriod,
      goalType,
      goalPeriod,
      goalEveryNPeriod,
      archivedAt,
      deletedAt,
      tasks,
      tags,
      completedDates,
    }
  }
`;
