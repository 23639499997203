import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';

import {FormMaterialSelectField} from 'formik/FormikMaterialFields';
import {useFormikContext, Field} from "formik";

import * as constants from "utils/constants";

export default function EventRepeat() {
    const { values, setFieldValue } = useFormikContext();

    const goalType = values.goalType;
    const goalEveryNPeriod = values.goalEveryNPeriod;

    let goalTypeWidth = 12;
    if (goalType === constants.GOAL_TYPE_PER || goalType === constants.GOAL_TYPE_EVERY) {
        goalTypeWidth = 4;
    }

    return (
    // state = {
    //     size: 1,
    // };
    //
    // render() {


        <FormControl style={{width: '100%'}}>
            <Grid container spacing={1}>
                {goalType !== constants.GOAL_TYPE_PER &&
                    <Grid item xs={goalTypeWidth}>
                        <Field
                            name="goalType"
                            component={FormMaterialSelectField}
                            label="Frequency"
                            options={constants.goalTypeOptions}
                        />
                     </Grid>
                }
                {goalType === constants.GOAL_TYPE_EVERY &&
                    <Grid item xs={3}>
                        <Field
                            name="goalEveryNPeriod"
                            component={FormMaterialSelectField}
                            label=""
                            options={constants.goalEveryNPeriodOptions}
                        />
                    </Grid>
                }
                {goalType === constants.GOAL_TYPE_EVERY &&
                    <Grid item xs={5}>
                        <Field
                            name="goalPeriod"
                            component={FormMaterialSelectField}
                            label=""
                            options={constants.goalPeriodOptions}
                        />
                    </Grid>
                }

                {goalType === constants.GOAL_TYPE_PER &&
                    <Grid item xs={3}>
                        <Field
                            name="goalEveryNPeriod"
                            component={FormMaterialSelectField}
                            label="Frequency"
                            options={constants.goalEveryNPeriodOptions}
                        />
                    </Grid>
                }
                {goalType === constants.GOAL_TYPE_PER &&
                    <Grid item xs={goalTypeWidth}>
                        <Field
                            name="goalType"
                            component={FormMaterialSelectField}
                            label=""
                            options={constants.goalTypeOptions}
                        />
                    </Grid>
                }
                {goalType === constants.GOAL_TYPE_PER &&
                    <Grid item xs={5}>
                        <Field
                            name="goalPeriod"
                            component={FormMaterialSelectField}
                            label=""
                            options={constants.goalPeriodOptions}
                        />
                    </Grid>
                }
            </Grid>
        </FormControl>
    );
}
