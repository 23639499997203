import React, {useEffect, useState} from 'react';
import moment from "moment";
import get from "lodash/get";

import CheckIcon from "@material-ui/icons/Check";

import {useCompleteNote} from "api/NoteApi";
import ProgressIconButton from "utils/ProgressIconButton";
import {FormDateTimePickerField, FormMaterialTextField} from "../../formik/FormikMaterialFields";
import {convertUtcToFormDate} from "../../utils/form";
import {Field, Form, Formik} from "formik";
import {DialogContent} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ProgressSubmitButton from "../../formik/ProgressSubmitButton";
import Dialog from "@material-ui/core/Dialog";

export default function CompleteButton({
    note,
    completedDate = {},
    onCompleted,
    icon = <CheckIcon fontSize="inherit"/>,
    iconSize = 'small',
    iconEdge = false,
}) {
    const completedAt = get(completedDate, "completedAt", null);
    const completedAtFormatted = completedAt ? convertUtcToFormDate(completedAt) : moment.utc().format();
    const [values, setValues] = useState({
        ...completedDate,
        completedAt: completedAtFormatted,
    });
    const [data, isLoading, completeNote] = useCompleteNote(note.id, values);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (data && typeof onCompleted === 'function') {
            onCompleted(data);
        }

        setIsDialogOpen(false);
    }, [data]);

    const onOpen = () => {
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            <Formik
                initialValues={values}
                enableReinitialize
                onSubmit={(values) => {
                    //console.log('values', values);
                    setValues(values)
                    completeNote();
                }}
            >
                {({values, setFieldValue, errors, submitForm}) => {
                    // console.log('values errors', values, errors);
                    return (
                        <React.Fragment>
                            <Dialog open={isDialogOpen} fullScreen={false}>
                                <DialogContent>
                                    <Form>
                                        <Field
                                            name={`completedAt`}
                                            label={"Completed at"}
                                            component={FormDateTimePickerField}
                                            onChange={(date) => {
                                                //console.log('completedAt onchange', date, convertUtcToFormDate(date));
                                                setFieldValue(`completedAt`, convertUtcToFormDate(date));
                                            }}
                                            fullWidth
                                        />
                                        <Field
                                            name={`comment`}
                                            label={"Note"}
                                            component={FormMaterialTextField}
                                            fullWidth
                                            multiline
                                            rows={3}
                                        />
                                    </Form>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                                    </Button>
                                    <ProgressSubmitButton
                                        loading={isLoading}
                                        text={"Save"}
                                        variant={'text'}
                                    />
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>
                    )
                }}
            </Formik>
            <ProgressIconButton
                loading={isLoading}
                onClick={(e) => {
                    e.stopPropagation();

                    onOpen();
                }}
                size={iconSize}
                buttonColor={'inherit'}
                icon={icon}
                edge={iconEdge}
                tooltipTitle={"Complete"}
            />
        </>
    );
}
