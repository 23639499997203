import {generatePath} from 'react-router-dom';

// ------------------- PRODUCT CATEGORIES -------------------
export const ROUTE_CATEGORIES = '/categories/:page?';
export const ROUTE_CATEGORIES_NEW = '/categories/new';
export const ROUTE_CATEGORY = '/category/:id';

export function getCategoriesPath(page = null, searchText = null) {
    return generatePath(ROUTE_CATEGORIES, {page, searchText});
}

export function getCategoryPath(id) {
    return generatePath(ROUTE_CATEGORY, {id});
}

// ------------------- GOALS -------------------
export const ROUTE_GOALS = '/goals';
