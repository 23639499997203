import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Field, useFormikContext} from 'formik';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export default function Size() {
    const { values, setFieldValue } = useFormikContext();

    return (
        <FormControl style={{width: '100%', textAlign: 'center'}}>
            <Field name="size" component="input" type="hidden"/>
            <InputLabel shrink={true}>Size</InputLabel>
            <div style={{marginTop: 16}}>
                <Button size="small"
                        style={{minWidth: 40}}
                        color={values.size === 0 ? 'primary' : undefined}
                        variant={values.size === 0 ? 'contained' : undefined}
                        onClick={() => {
                            setFieldValue('size', 0);
                            //this.setState({size: 1});
                        }}>
                    5min
                </Button>
                <Button size="small"
                        style={{minWidth: 40}}
                        color={values.size === 1 ? 'primary' : undefined}
                        variant={values.size === 1 ? 'contained' : undefined}
                        onClick={() => {
                            setFieldValue('size', 1);
                            //this.setState({size: 1});
                        }}>
                    2hours
                </Button>
                <Button size="small"
                        style={{minWidth: 40}}
                        color={values.size === 2 ? 'primary' : undefined}
                        variant={values.size === 2 ? 'contained' : undefined}
                        onClick={() => {
                            setFieldValue('size', 2);
                            //this.setState({size: 1});
                        }}>
                    4hours
                </Button>
                <Button size="small"
                        style={{minWidth: 40}}
                        color={values.size === 3 ? 'primary' : undefined}
                        variant={values.size === 3 ? 'contained' : undefined}
                        onClick={() => {
                            setFieldValue('size', 3);
                            //this.setState({size: 1});
                        }}>
                    1 day
                </Button>
                <Button size="small"
                        style={{minWidth: 40}}
                        color={values.size === 4 ? 'primary' : undefined}
                        variant={values.size === 4 ? 'contained' : undefined}
                        onClick={() => {
                            setFieldValue('size', 4);
                            //this.setState({size: 1});
                        }}>
                    2 days+
                </Button>

                {/*<Button size="small"*/}
                {/*        style={{minWidth: 40}}*/}
                {/*        color={values.size === 1 ? 'primary' : undefined}*/}
                {/*        variant={values.size === 1 ? 'contained' : undefined}*/}
                {/*        onClick={() => {*/}
                {/*            setFieldValue('size', 1);*/}
                {/*            //this.setState({size: 1});*/}
                {/*        }}>*/}
                {/*    S*/}
                {/*</Button>*/}
                {/*<Button size="small"*/}
                {/*        style={{minWidth: 40}}*/}
                {/*        color={values.size === 2 ? 'primary' : undefined}*/}
                {/*        variant={values.size === 2 ? 'contained' : undefined}*/}
                {/*        onClick={() => {*/}
                {/*            setFieldValue('size', 2);*/}
                {/*            //this.setState({size: 2});*/}
                {/*        }}>*/}
                {/*    M*/}
                {/*</Button>*/}
                {/*<Button size="small"*/}
                {/*        style={{minWidth: 40}}*/}
                {/*        color={values.size === 3 ? 'primary' : undefined}*/}
                {/*        variant={values.size === 3 ? 'contained' : undefined}*/}
                {/*        onClick={() => {*/}
                {/*            setFieldValue('size', 3);*/}
                {/*            //this.setState({size: 3});*/}
                {/*        }}>*/}
                {/*    L*/}
                {/*</Button>*/}
            </div>
        </FormControl>
    );
}
