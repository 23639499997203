import React, {useMemo} from "react";
import {isEmpty} from "lodash";

import * as noteTypes from 'utils/noteTypes';
import {getNotePriority} from 'utils/notes';
import {transformToFormData} from 'utils/notes';
import {useNotes} from "contexts/NotesContext";

// export default function usePrioritizedNextActions(tagsFilter = []) {
//     const {loading, error, data} = useQuery(noteGql.GET_NOTES);
//
//     if (loading || error) return [];
//
//     let prioritizedNextActionNotes = data.notes.filter(note => {
//         return note.type === noteTypes.TASK && note.archivedAt === null && note.deletedAt === null;
//     }).map(transformToFormData).sort(function (a, b) {
//         let aPriority = getNotePriority(a);
//         let bPriority = getNotePriority(b);
//         let aSize = a.size;
//         let bSize = b.size;
//
//         if (aPriority > bPriority) {
//             return -1;
//         }
//
//         if (aPriority < bPriority) {
//             return 1;
//         }
//
//         if (aPriority === bPriority && aSize < bSize) {
//             return -1;
//         } else if (aPriority === bPriority && aSize > bSize) {
//             return 1;
//         }
//
//         return 0;
//     });
//
//     prioritizedNextActionNotes = prioritizedNextActionNotes.filter(filterByTags(tagsFilter));
//
//     return prioritizedNextActionNotes.slice(0, 15);
// };

export default function usePrioritizedNextActions(tagsFilter = []) {
    const { notes, loading, errors } = useNotes();

    return useMemo(() => {
        if (!notes || loading || !isEmpty(errors)) return [];

        return notes.filter(note => {
            return note.type === noteTypes.TASK && note.archivedAt === null;
        }).sort(function (a, b) {
            let aPriority = getNotePriority(a);
            let bPriority = getNotePriority(b);
            let aSize = a.size;
            let bSize = b.size;

            if (aPriority > bPriority) {
                return -1;
            }

            if (aPriority < bPriority) {
                return 1;
            }

            if (aPriority === bPriority && aSize < bSize) {
                return -1;
            } else if (aPriority === bPriority && aSize > bSize) {
                return 1;
            }

            return 0;
        });
    }, [notes, loading, errors]);
};

// export const getPrioritizedNextActionsBySelectedGroup = createSelector(
//     getNotesById,
//     getAllNotes,
//     selectedGroupId,
//     (notesById, allNotes, selectedGroupId) => {
//         let prioritizedNextActionNotes = allNotes.filter(id => {
//             return notesById[id].typeId === noteTypes.TASK && notesById[id].archived === null && notesById[id].deleted === null && notesById[id].groupId === selectedGroupId;
//         }).sort(function (a, b) {
//             let aPriority = getNotePriority(notesById[a]);
//             let bPriority = getNotePriority(notesById[b]);
//             let aSize = notesById[a].size;
//             let bSize = notesById[b].size;
//
//             if (aPriority > bPriority) {
//                 return -1;
//             }
//
//             if (aPriority < bPriority) {
//                 return 1;
//             }
//
//             if (aPriority === bPriority && aSize < bSize) {
//                 return -1;
//             } else if (aPriority === bPriority && aSize > bSize) {
//                 return 1;
//             }
//
//             return 0;
//         });
//
//         return prioritizedNextActionNotes.map(id => {
//             return notesById[id];
//         });
//     }
// );
