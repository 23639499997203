import React, { useState, useCallback, useLayoutEffect } from "react";
import ReactResizeDetector from 'react-resize-detector';

import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Paper from '@material-ui/core/Paper';

import useWindowSize from './useWindowSize';
import useDimensions from './useDimensions';

const FillHeightContainer = ({children, header, ...props}) => {
    const size = useWindowSize();
    const [ref, dimensions, node, forceRecalculate] = useDimensions({ liveMeasure: false });

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 50,
        target: node ? node : undefined,
    });

    const onResize = (width, height) => {
        forceRecalculate();
        // console.log('onResize Header', width, height);
    };

    // console.log('useWindowSize', size);
    // console.log('useDimensions', dimensions);
    // console.log('trigger', trigger);

    const fromTop = typeof dimensions.y === 'undefined' ? 0 :  dimensions.y;
    const height = typeof size.height === 'undefined' ? 0 : (size.height - fromTop);
    const minHeight = height <= 300 ? 300 : height;
    // console.log('FillHeightContainer: fromTop, height, minHeight', fromTop, height, minHeight);
    return (
        <>
            <Paper elevation={trigger ? 3 : 0} square style={{
                position: 'relative',
                borderBottom: '1px solid #eee',
                zIndex: 2,
                //height: 54,
                //minHeight: 54,

                // overflow: 'hidden',
                // display: 'flex',
                // alignItems: 'center',
            }}>
                <ReactResizeDetector handleHeight onResize={onResize}>
                    {header}
                </ReactResizeDetector>
            </Paper>
            <div id={'fill-height-container'} ref={ref} style={{
                height: minHeight,
                overflowY: 'auto',
            }}>
                {children}
            </div>
        </>
    );
};

export default FillHeightContainer;