import 'react-sortable-tree/style.css';

import React, {useEffect, useState} from 'react';
import SortableTree, { getVisibleNodeCount } from 'react-sortable-tree';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import {useGetCategoriesTree, useSaveCategoriesTree} from "api/CategoryApi";
import {
    ROUTE_CATEGORIES_NEW,
    getCategoriesPath,
    getCategoryPath,
} from 'utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import DataLoadingIndicator from "utils/DataLoadingIndicator";
import FillHeightContainer from "utils/FillHeightContainer";
import CategoryNode from "./CategoryNode";

function Categories({match, history}) {
    const [categoriesTree, treeTotalCount, isCategoriesTreeLoading, categoriesTreeErrors, fetchCategoriesTree] = useGetCategoriesTree();

    const [treeData, setTreeData] = useState([]);
    const [saveCategoriesTree] = useSaveCategoriesTree(treeData);

    useEffect(() => {
        fetchCategoriesTree();
    }, []);

    useEffect(() => {
        if (categoriesTree !== null) {
            const expandedTree = categoriesTree.map((category) => {
                return {
                    ...category,
                    expanded: true,
                }
            });

            setTreeData(expandedTree);
        }
    }, [categoriesTree]);

    if (!categoriesTree || isCategoriesTreeLoading) {
        return (<DataLoadingIndicator />);
    }
    const count = getVisibleNodeCount({treeData: treeData});

    return (
        <React.Fragment>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        <PageTitleWithButton
                            pageTitle={"Categories"}
                            buttonTitle={"New"}
                            buttonRoute={ROUTE_CATEGORIES_NEW}
                        />
                    </div>
                </>

            }>
                <Paper square elevation={0}>
                    <div style={{height: count * 62 + 5}}>
                        <SortableTree
                            treeData={treeData}
                            onChange={treeData => {
                                setTreeData(treeData);
                                saveCategoriesTree();
                            }}
                            generateNodeProps={rowInfo => ({
                                buttons: [
                                    <IconButton onClick={() => history.push(getCategoryPath(rowInfo.node.id))} style={{color: 'black'}}>
                                        <EditIcon fontSize="small" color="inherit" />
                                    </IconButton>,
                                ],
                            })}
                            style={{color: 'black'}}
                            // nodeContentRenderer={CategoryNode}
                        />
                    </div>
                </Paper>
            </FillHeightContainer>
        </React.Fragment>
    );
}

export default Categories;
