import React, {useEffect, useState} from 'react';
import moment from "moment";

import {Field, FieldArray, useFormikContext} from 'formik';
import Grid from '@material-ui/core/Grid';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

import {FormDateTimePickerField} from "formik/FormikMaterialFields";
import {convertUtcToFormDate} from "utils/form";

function CompletedDatesForm() {
    const {setFieldValue, values} = useFormikContext();

    return (
        <React.Fragment>
            <div style={{padding: "0 8px 8px"}}>
                <FieldArray name="completedDates"
                            render={arrayHelpers => {
                                return (
                                    <React.Fragment>
                                        {values.completedDates && values.completedDates.length > 0 ? (
                                            <List dense={true}>
                                                {values.completedDates.map((completedDate, completedDateIndex) => (
                                                    <Grid item xs={12} key={completedDateIndex}>
                                                        <ListItem
                                                            style={{
                                                                paddingLeft: 0,
                                                                paddingRight: 0,
                                                            }}
                                                        >
                                                            <Field
                                                                name={`completedDates.${completedDateIndex}.completedAt`}
                                                                component={FormDateTimePickerField}
                                                                onChange={(date) => {
                                                                    console.log('EventDate onchange', date, convertUtcToFormDate(date));
                                                                    setFieldValue(`completedDates.${completedDateIndex}.completedAt`, convertUtcToFormDate(date));
                                                                }}
                                                                fullWidth
                                                            />
                                                            <IconButton
                                                                onClick={() => arrayHelpers.remove(completedDateIndex)}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </ListItem>
                                                    </Grid>
                                                ))}
                                            </List>
                                        ) : null}

                                        {/*<Button variant="outlined"*/}
                                        {/*        onClick={() => arrayHelpers.push({*/}
                                        {/*            completedAt: moment.utc().format()*/}
                                        {/*        })}>*/}
                                        {/*    Add a new Completed Date*/}
                                        {/*</Button>*/}
                                    </React.Fragment>
                                )
                            }}
                />
            </div>
        </React.Fragment>
    );
}

export default CompletedDatesForm;
