import React from 'react';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

function ProgressButton({loading, disabled = false, text, startIcon = null, onClick, type="button", variant="contained", color="primary", fullWidth = false}) {
    return (
        <div style={{
            display: fullWidth ? 'block' : 'inline-block',
            position: 'relative',
            ...(fullWidth && {width: '100%'}),
        }}>
            <Button
                startIcon={startIcon}
                variant={variant}
                color={color}
                disabled={loading || disabled}
                onClick={onClick}
                type={type}
                fullWidth={fullWidth}
            >
                {text}
            </Button>
            {loading && <CircularProgress
                size={24}
                color={color}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                }}
            />}
        </div>
    );
}
export default ProgressButton;