import React from "react";
import { styled } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
// import {
//     DatePicker
// } from "material-ui-pickers";
import { DatePicker, DateTimePicker , KeyboardDatePicker } from "@material-ui/pickers";

import {useTheme, makeStyles, createTheme} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import RootRef from '@material-ui/core/RootRef';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#FDD835',
    },
    type: 'dark',
  },
});

const StyledTextField = styled(({ nomargin, theme, ...other }) => <TextField {...other} />)({
    marginTop: props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1) + 'px',
    marginBottom: props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1) + 'px',
    '.outlinedInput': {
        root: {
            color: 'white',
        }
    }
});

const StyledFormControl = styled(({ nomargin, theme, ...other }) => <FormControl {...other} />)({
    marginTop: props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1) + 'px',
    marginBottom: props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1) + 'px',
});

// const MyButton = styled(({ color, ...other }) => <Button {...other} />)({
//     background: props => {
//         console.log('MyButton, props', props);
//         return
//             props.color === 'red'
//             ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
//             : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)';
//
//     },
//     border: 0,
//     borderRadius: 3,
//     boxShadow: props =>
//         props.color === 'red'
//             ? '0 3px 5px 2px rgba(255, 105, 135, .3)'
//             : '0 3px 5px 2px rgba(33, 203, 243, .3)',
//     color: 'white',
//     height: 48,
//     padding: '0 30px',
//     margin: 8,
// });

const MyThemeComponent = styled('div')(({
                                            theme
                                        }) => ({
    padding: theme.spacing(1),
}));

const StyledTextField2 = styled(TextField)`
  && {
    margin-top: ${props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1)}px;
    margin-bottom: ${props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1)}px;
  }
`;

const StyledDatePicker = styled(DatePicker)`
  && {
    margin-top: ${props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1)}px;
    margin-bottom: ${props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1)}px;
  }
`;


export function MaterialDateTimePickerField({ helperText, error, value, ...props }) {
    const theme = useTheme();

    const inputLabelProps = !!value ? { shrink: true } : {};
    //value = !!value ? value : null;
    console.log('props', value, props);
    return (
        <>
            {/*<KeyboardDatePicker*/}
            {/*autoOk*/}
            {/*variant="inline"*/}
            {/*inputVariant="outlined"*/}
            {/*label="With keyboard"*/}
            {/*format="MM/dd/yyyy"*/}
            {/*value={null}*/}
            {/*InputAdornmentProps={{ position: "start" }}*/}
            {/*onChange={date => console.log(date)}*/}
            {/*/>*/}
            <DateTimePicker
                autoOk
                clearable
                inputVariant="outlined"
                theme={theme}
                helperText={helperText}
                ampm={false}
                error={error}
                variant="outlined"
                InputLabelProps={inputLabelProps}
                value={value}
                fullWidth
                {...props}
            />
        </>
    );
}

export function MaterialDatePickerField({ helperText, error, value, ...props }) {
    const theme = useTheme();

    const inputLabelProps = !!value ? { shrink: true } : {};
    //value = !!value ? value : null;
    console.log('props', value, props);
    return (
        <>
        {/*<KeyboardDatePicker*/}
            {/*autoOk*/}
            {/*variant="inline"*/}
            {/*inputVariant="outlined"*/}
            {/*label="With keyboard"*/}
            {/*format="MM/dd/yyyy"*/}
            {/*value={null}*/}
            {/*InputAdornmentProps={{ position: "start" }}*/}
            {/*onChange={date => console.log(date)}*/}
        {/*/>*/}
        <StyledDatePicker
            autoOk
            clearable
            //ref={inputLabel}
            //labelWidth={labelWidth}
            inputVariant="outlined"
            theme={theme}
            helperText={helperText}
            error={error}
            variant="outlined"
            InputLabelProps={inputLabelProps}
            value={value}
            {...props}
        />
        </>
    );
}

// const StyledTextField = styled(({ nomargin, theme, ...other }) => <TextField {...other} />)({
//     marginTop: props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1) + 'px',
//     marginBottom: props => props.nomargin !== undefined && props.nomargin ? 0 : props.theme.spacing(1) + 'px',
//     '.outlinedInput': {
//         root: {
//             color: 'white',
//         }
//     }
// });

const useLightModeStylesTextField = makeStyles(theme => ({
    root: {
        marginTop: props => props.nomargin !== undefined && props.nomargin ? 0 : theme.spacing(1) + 'px',
        marginBottom: props => props.nomargin !== undefined && props.nomargin ? 0 : theme.spacing(1) + 'px',
    },
}));

const useDarkModeStylesTextField = makeStyles(theme => ({
    root: {
        marginTop: props => props.nomargin !== undefined && props.nomargin ? 0 : theme.spacing(1) + 'px',
        marginBottom: props => props.nomargin !== undefined && props.nomargin ? 0 : theme.spacing(1) + 'px',
        '& label.Mui-focused': {
            color: 'white',
        },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: 'green',
        // },
        '& .MuiInputLabel-root': {
            color: 'white',
        },
        '& .MuiOutlinedInput-input': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        }
    },
}));

export function MaterialTextField({ helperText, error, mode, ...props }) {
    const lightModeClasses  = useLightModeStylesTextField(props);
    const theme = useTheme();
    //const darkModeClasses  = useDarkModeStylesTextField(props);

    //const classes = typeof mode !== 'undefined' && mode === 'dark' ? darkModeClasses : lightModeClasses;

    return (
        <React.Fragment>
            {typeof mode !== 'undefined' && mode === 'dark' ? (
                <ThemeProvider theme={darkTheme}>
                    <StyledTextField
                        classes={lightModeClasses}
                        theme={theme}
                        helperText={helperText}
                        error={error}
                        variant="outlined"
                        {...props}
                    />
                </ThemeProvider>
            ) : (
                <StyledTextField
                    classes={lightModeClasses}
                    theme={theme}
                    helperText={helperText}
                    error={error}
                    variant="outlined"
                    {...props}
                />
            )}
        </React.Fragment>
    );
}

export function MaterialSelectField({ helperText = '', error, displayEmpty = false, options, nomargin = 0, hasEmpty = false, emptyValue= '', emptyLabel = '', label = '', ...props }) {
    const theme = useTheme();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current) {
            console.log('width', inputLabel.current.offsetWidth);
            setLabelWidth(inputLabel.current.offsetWidth);
        }
    }, []);

    const customProps = {};
    if (displayEmpty) {
        customProps['shrink'] = true;
    } else {
        customProps['ref'] = inputLabel;
    }

    return (
        <StyledFormControl theme={theme} nomargin={nomargin} variant="outlined" margin="dense" fullWidth error={error}>
            <InputLabel margin="dense" {...customProps} ref={inputLabel} id="simple-select-outlined-label">
                {label}
            </InputLabel>
            <Select
                displayEmpty={displayEmpty}
                labelId="simple-select-outlined-label"
                id="simple-select-outlined"
                labelWidth={labelWidth}
                error={error}
                {...props}
            >
                {hasEmpty ? (
                    <MenuItem value={emptyValue}>
                        <em>{emptyLabel}</em>
                    </MenuItem>
                ) : null}
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText ? (
                <FormHelperText>{helperText}</FormHelperText>
            ) : null}
        </StyledFormControl>
    );
    //
    // return (
    //     <StyledTextField
    //         theme={theme}
    //         select
    //         helperText={helperText}
    //         variant="outlined"
    //         error={error}
    //         {...props}
    //     >
    //         {hasEmpty ? (
    //             <MenuItem value={emptyValue}>
    //                 <em>{emptyLabel}</em>
    //             </MenuItem>
    //         ) : null}
    //         {options.map(option => (
    //             <MenuItem key={option.value} value={option.value}>
    //                 {option.label}
    //             </MenuItem>
    //         ))}
    //     </StyledTextField>
    // );
}

export function MaterialMultiSelectChip({ options, label, fullWidth, ...props }) {
    const theme = useTheme();
    const [labelWidth, setLabelWidth] = React.useState(0);
    const labelEl = React.useRef(null);

    React.useEffect(() => {
        console.log('labelEl', labelEl, labelEl.current, labelEl.current.offsetWidth);
        setLabelWidth(labelEl.current.offsetWidth);
    }, []);

    // const measuredRef = useCallback(labelEl => {
    //     console.log('labelEl', labelEl, labelEl.current);
    //     if (labelEl !== null && labelEl.current !== null) {
    //         console.log('labelEl2', labelEl, labelEl.current, labelEl.current.offsetWidth);
    //         setLabelWidth(labelEl.current.offsetWidth);
    //     }
    // }, []);

    console.log('MaterialMultiSelectChip options', options);

    return (
        <FormControl
            theme={theme}
            variant="outlined"
            fullWidth={fullWidth}
        >
            <RootRef rootRef={labelEl}>
                <InputLabel htmlFor="select-multiple-chip">{label}</InputLabel>
            </RootRef>
            <Select
                multiple
                input={<OutlinedInput
                    id="select-multiple-chip"
                    labelWidth={labelWidth}
                    margin={"dense"}
                />}
                renderValue={selected => {
                    const selectedOptions = options.filter(option => {
                        return selected.indexOf(option.value) > -1;
                    });

                    return (
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                        }}>
                            {selectedOptions.map(option => (
                                <Chip key={option.value} label={option.label} style={{}} />
                            ))}
                        </div>
                    )
                }}
                {...props}
            >
                {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
