import React, {useState, useEffect} from 'react';

import {getCategory} from 'api/CategoryApi';
import CategoryForm from 'pages/Categories/CategoryForm'
import DataLoadingIndicator from "utils/DataLoadingIndicator";
// import LinkBack from "utils/LinkBack";
import PageTitle from "utils/PageTitle";
import FillHeightContainer from "utils/FillHeightContainer";
import ProgressButton from "utils/ProgressButton";

function CategoryEdit({ match }) {
    const categoryId = match.params.id;
    const [isLoading, setIsLoading] = useState(true);
    const [category, setCategory] = useState({});

    useEffect(() => {
        getCategory(categoryId)
            .then(function (category) {
                setCategory(category);
            })
            .catch(function (error) {
                // handle error

            })
            .finally(function() {
                setIsLoading(false);
            });
    }, []);

    if (isLoading) {
        return (<DataLoadingIndicator />);
    }

    return (
        <div>
            <FillHeightContainer header={
                <>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: 54,
                        padding: '0 16px',
                    }}>
                        {/*<LinkBack to={getCategoriesPath()} text={"Atgal"}/>*/}
                        <PageTitle title={"Category edit"} gutterBottom/>
                        <span style={{flex: 1}} />
                        {/*<ProgressButton*/}
                        {/*    loading={isSubmitting}*/}
                        {/*    text={"Išsaugoti"}*/}
                        {/*    onClick={(e) => {*/}
                        {/*        handleSubmitMyForm(e);*/}
                        {/*    }}*/}
                        {/*    color="primary"*/}
                        {/*/>*/}
                    </div>
                </>
            }>
                <CategoryForm
                    isNew={false}
                    initialValues={category}
                />
            </FillHeightContainer>



        </div>
    );
}

export default CategoryEdit;
