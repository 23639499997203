import React, {useEffect, useState} from 'react';

import Grid from "@material-ui/core/Grid";
import Badge from '@material-ui/core/Badge';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';

import {getDaysBeforeEventText} from 'utils/notes';
import NotesGridContainer from "components/note/NotesGridContainer";

import useEvents from "selectors/useEvents";
import useNextActions from "selectors/useNextActions";
import useGoals from "selectors/useGoals";
import TagsSelect from "../components/tag/TagsSelect";
import {useSelector} from "react-redux";
import get from "lodash/get";
import {useGetGoalsSchedule} from "../api/NoteApi";
import DataLoadingIndicator from "../utils/DataLoadingIndicator";
import {isEmpty} from "lodash";

const Assistant = () => {
    const [currentTab, setCurrentTab] = useState(1);
    const tagsFilter = useSelector((state) => {
        return get(state, "filters.tags", []);
    });

    const events = useEvents(tagsFilter);
    const nextActions = useNextActions(tagsFilter);
    const goals = useGoals(tagsFilter);
    const [goalsSchedule, isGoalsScheduleLoading, goalsScheduleErrors, fetchGoalsSchedule] = useGetGoalsSchedule();

    useEffect(() => {
        fetchGoalsSchedule();
    }, []);

    if (!goalsSchedule || isGoalsScheduleLoading) {
        return (<DataLoadingIndicator />);
    }

    if (!isEmpty(goalsScheduleErrors)) {
        return <>Error</>;
    }

    const todayGoals = get(goalsSchedule, 'today', []);
    const upcomingGoals = get(goalsSchedule, 'upcoming', []);

    return (
        <>
            <div>
                <Hidden lgUp>
                    <Tabs
                        value={currentTab}
                        onChange={(event, value) => {
                            setCurrentTab(value);
                        }}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        centered
                    >
                        {events.length > 0 ?
                            <Tab label={<Badge color="primary" badgeContent={getDaysBeforeEventText(events[0])}>
                                <Typography style={{padding: '0 15px'}}>Events</Typography>
                            </Badge>} style={{maxWidth: 320}}/>
                            :
                            <Tab label="Events" style={{maxWidth: 320}}/>
                        }
                        <Tab label="Actions" style={{maxWidth: 320}}/>
                        <Tab label="Goals" style={{maxWidth: 320}}/>
                    </Tabs>
                    <SwipeableViews
                        axis={'x'}
                        index={currentTab}
                        onChangeIndex={(index) => {setCurrentTab(index)}}
                    >
                        <div style={{padding: 8}}>
                            {events.length > 0
                                ?
                                    <>
                                        <NotesGridContainer notes={events} />
                                    </>
                                :
                                <div style={{textAlign: 'center'}}>No events</div>
                            }
                        </div>
                        <div style={{padding: 8}}>
                            {nextActions.length > 0
                                ?
                                <>
                                    <NotesGridContainer notes={nextActions} />
                                </>
                                :
                                <div style={{textAlign: 'center'}}>No actions</div>
                            }
                        </div>
                        <div style={{padding: 8}}>
                            <div>Today</div>
                            {todayGoals.length > 0
                                ?
                                    <>
                                        <NotesGridContainer notes={todayGoals} />
                                    </>
                                :
                                <div style={{textAlign: 'center'}}>No goals today</div>
                            }
                            <div>Upcoming</div>
                            {upcomingGoals.length > 0
                                ?
                                    <>
                                        <NotesGridContainer notes={upcomingGoals} />
                                    </>
                                :
                                <div style={{textAlign: 'center'}}>No upcoming goals</div>
                            }
                        </div>
                    </SwipeableViews>
                </Hidden>
                <Hidden mdDown>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <div style={{
                                marginTop: 20,
                                marginBottom: 15,
                                textAlign: 'center',
                            }}>
                                {events.length > 0 ?
                                    <Badge color="primary" badgeContent={getDaysBeforeEventText(events[0])}>
                                        <Typography variant="button" gutterBottom style={{padding: '0 15px'}}>
                                            Events
                                        </Typography>
                                    </Badge>
                                    :
                                    <Typography variant="button" gutterBottom style={{
                                        marginTop: 20,
                                        marginBottom: 15,
                                        textAlign: 'center',
                                    }}>
                                        Events
                                    </Typography>
                                }
                            </div>
                            {events.length > 0
                                ?
                                <>
                                    <NotesGridContainer notes={events} />
                                </>
                                :
                                <div style={{textAlign: 'center'}}>No events</div>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{
                                marginTop: 20,
                                marginBottom: 15,
                                textAlign: 'center',
                            }}>
                                <Typography variant="button" gutterBottom style={{
                                    marginTop: 20,
                                    marginBottom: 15,
                                    textAlign: 'center',
                                }}>
                                    Actions
                                </Typography>
                            </div>
                            {nextActions.length > 0
                                ?
                                <>
                                    <NotesGridContainer notes={nextActions} />
                                </>
                                :
                                <div style={{textAlign: 'center'}}>No actions</div>
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <div style={{
                                marginTop: 20,
                                marginBottom: 15,
                                textAlign: 'center',
                            }}>
                                <Typography variant="button" gutterBottom style={{
                                    marginTop: 20,
                                    marginBottom: 15,
                                    textAlign: 'center',
                                }}>
                                    Goals
                                </Typography>
                            </div>
                            <div>Today</div>
                            {todayGoals.length > 0
                             ?
                             <>
                                 <NotesGridContainer notes={todayGoals} />
                             </>
                             :
                             <div style={{textAlign: 'center'}}>No goals today</div>
                            }
                            <div>Upcoming</div>
                            {upcomingGoals.length > 0
                             ?
                             <>
                                 <NotesGridContainer notes={upcomingGoals} />
                             </>
                             :
                             <div style={{textAlign: 'center'}}>No upcoming goals</div>
                            }
                        </Grid>
                    </Grid>
                </Hidden>
            </div>
        </>
    );
};

export default Assistant;
