import React, {useMemo} from "react";

import moment from 'moment';
import {isEmpty} from 'lodash';

import * as noteTypes from 'utils/noteTypes';
import {transformToFormData} from 'utils/notes';
import {filterByTags} from "utils/filters";
import {useNotes} from "contexts/NotesContext";

// export default function useEvents(tagsFilter = []) {
//     const {loading, error, data} = useQuery(noteGql.GET_NOTES);
//     //console.log('useEvents', loading, error, data);
//     if (loading || error) return [];
//
//     let prioritizedEventNotes = data.notes.filter(note => {
//         return note.type === noteTypes.EVENT && note.deletedAt === null && note.archivedAt === null;
//     }).map(transformToFormData).sort(function (a, b) {
//         let aDate = moment(a.eventDate);
//         let bDate = moment(b.eventDate);
//
//         let diff = aDate.diff(bDate, 'days', true);
//
//         if (diff > 0) {
//             return 1;
//         } else if (diff < 0) {
//             return -1;
//         }
//
//         return 0;
//     });
//
//     prioritizedEventNotes = prioritizedEventNotes.filter(filterByTags(tagsFilter));
//
//     return prioritizedEventNotes.slice(0, 15);
// };

export default function useEvents(tagsFilter = []) {
    const { notes, loading, errors } = useNotes();

    return useMemo(() => {
        if (!notes || loading || !isEmpty(errors)) return [];

        let prioritizedEventNotes = notes.filter(note => {
            return note.type === noteTypes.EVENT && note.archivedAt === null;
        }).sort(function (a, b) {
            let aDate = moment(a.eventDate);
            let bDate = moment(b.eventDate);

            let diff = aDate.diff(bDate, 'days', true);

            if (diff > 0) {
                return 1;
            } else if (diff < 0) {
                return -1;
            }

            return 0;
        });
        console.log('prioritizedEventNotes', prioritizedEventNotes);
        prioritizedEventNotes = prioritizedEventNotes.filter(filterByTags(tagsFilter));

        return prioritizedEventNotes.slice(0, 15);
    }, [notes, loading, errors]);
};


