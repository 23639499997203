import React from 'react';

import {Field} from 'formik';

import * as noteTypes from 'utils/noteTypes'
import {FormMaterialSelectField} from "formik/FormikMaterialFields";

export default function NoteTypes() {
  const options = noteTypes.allTypes.map((noteType) => (
    {
      value: noteType.id,
      label: noteType.value,
    }
  ));

  return (
    <>
      <Field
        name={'type'}
        label={'Type'}
        fullWidth
        options={options}
        component={FormMaterialSelectField}
      />
    </>
  );
}
