import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray, ErrorMessage, FastField} from 'formik';
import * as Yup from 'yup';
import uuidv4 from 'uuid/v4';
import {useMutation} from '@apollo/react-hooks';
import isArray from 'lodash/isArray';

import {useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import Button from '@material-ui/core/Button';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ImageIcon from '@material-ui/icons/Image';
import Avatar from "@material-ui/core/Avatar";
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FormHelperText from '@material-ui/core/FormHelperText';

import {FormMaterialTextField} from "formik/FormikMaterialFields";
import Note from "entities/Note";
import * as noteGql from 'gql/note';
import useUpdateNote from "components/note/useUpdateNote";
import {useUpsertNote} from "../../api/NoteApi";

const ProductTransferSchema = Yup.object().shape({
    // warehouseFrom: Yup.object({
    //   id: Yup.string().required('Šis laukas negali būti tuščias'),
    // }),
    // warehouseTo: Yup.object({
    //   id: Yup.string().required('Šis laukas negali būti tuščias'),
    // }),
    // products: Yup.array()
    //   .required('Pervėžime turi būti bent vienas produktas'),
});


const newNote = new Note();

//{ loading, error, data }
function NoteFormContainer({note, children, onChanged}) {


    // const [createNote, createNoteVariables] = useMutation(noteGql.CREATE_NOTE,
    //     {
    //         update(cache, {data: {createNote}}) {
    //
    //             const {notes} = cache.readQuery({query: noteGql.GET_NOTES});
    //
    //             cache.writeQuery({
    //                 query: noteGql.GET_NOTES,
    //                 data: {notes: notes.concat([createNote.note])},
    //             });
    //         }
    //     });
    // const [updateNote, updateNoteVariables] = useUpdateNote();
    const initialValues = note == null ? newNote : note;
    const [noteData, setNoteData] = useState(initialValues);
    const [upsertNoteData, upsertNoteIsLoading, upsertNoteErrors, upsertNote] = useUpsertNote(noteData);

    //console.log('initial values', initialValues);
    //console.log('loading, error, data', loading, error, data);
    useEffect(() => {
        if (upsertNoteData && typeof onChanged === 'function') {
            onChanged();
        }
    }, [upsertNoteData]);

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={ProductTransferSchema}
                onSubmit={(values) => {
                    console.log('values', values);

                    setNoteData({
                        ...note,
                        ...values,
                    });
                    upsertNote();

                    // if (note == null) {
                        // createNote({variables: {
                        //     ...values,
                        //     tasks: isArray(values.tasks) ? JSON.stringify(values.tasks) : values.tasks,
                        //     tags: isArray(values.tags) ? JSON.stringify(values.tags) : values.tags,
                        //     completedDates: isArray(values.completedDates) ? JSON.stringify(values.completedDates) : values.completedDates,
                        // }});
                    // } else {
                        // updateNote({
                        //     ...note,
                        //     ...values,
                        // });
                    // }
                }}
            >
                {({values, setFieldValue, errors, submitForm}) => {
                    //console.log('values errors', values, errors);
                    return (
                        <React.Fragment>
                            <Form>
                                {/*{children({loading: createNoteVariables.loading || updateNoteVariables.loading})}*/}
                                {children({loading: upsertNoteIsLoading})}
                            </Form>
                        </React.Fragment>
                    )
                }}
            </Formik>
        </React.Fragment>
    );
}

export default NoteFormContainer;
