import React from 'react';

import Grid from "@material-ui/core/Grid";
import Note from "components/note/Note";

export default function NotesGridContainer({notes}) {
    return (
        <>
            <Grid container spacing={2}>
                {notes.map((note, key) => (
                    <Grid item xs={12} xl={6} key={key}>
                        <Note note={note}/>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
