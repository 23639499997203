import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import logger from 'redux-logger'
import {
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import * as serviceWorker from './serviceWorker';


import { gql } from "apollo-boost";

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

// Import your reducers and routes here
import Welcome from './Welcome';
import Root from './Root';

// import reducers
import note from './reducers/note/';
import filters from './ducks/filters';
import toggles from './ducks/toggles';
import {NoteProvider} from 'components/note/NoteContext';
import {ConfirmationDialogProvider} from 'components/utils/ConfirmationDialogContext';
import {CompletedDatesDialogProvider} from 'components/utils/CompletedDatesDialogContext';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LoginPage from "components/login/LoginPage";
import RouteAuthenticated from "components/utils/RouteAuthenticated";
import {loadState, saveState} from "./localStorage";

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    background: {
      default: '#eee'
    },
    primary: {
      main: '#424242',
    },
    secondary: {
      contrastText: "rgba(33, 33, 33, 0.87)",
      main: '#FDD835',
    },
    text: {
      primary: 'rgba(33, 33, 33, 0.87)',
    },
    //type: 'dark',
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#FDD835',
    },
    type: 'dark',
  },
});

const persistedState = loadState();
console.log('persistedState', persistedState);

const store = createStore(
  combineReducers({
      note,
      //router: connectRouter(history),
      form,
      filters,
      toggles,
    /* Add your reducers here */
  }),
  persistedState,
  //applyMiddleware(routerMiddleware(history), thunk)
  applyMiddleware(thunk, logger)
);

store.subscribe(() => {
    saveState({
        filters: store.getState().filters,
        toggles: store.getState().toggles,
    });
});

ReactDOM.render(
  <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
              <NoteProvider>
                  <ConfirmationDialogProvider>
                      <CompletedDatesDialogProvider>
                          <Router>
                              <Switch>
                                  <Route exact path="/login" component={LoginPage}/>
                                  <RouteAuthenticated component={Root} />
                              </Switch>
                          </Router>
                      </CompletedDatesDialogProvider>
                  </ConfirmationDialogProvider>
              </NoteProvider>
          </MuiPickersUtilsProvider>
      </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
