export const EVENT_REPEAT_TYPE_DOES_NOT_REPEAT = 1;
export const EVENT_REPEAT_TYPE_DAILY = 2;
export const EVENT_REPEAT_TYPE_WEEKLY = 3;
export const EVENT_REPEAT_TYPE_MONTHLY = 4;
export const EVENT_REPEAT_TYPE_YEARLY = 5;
export const EVENT_REPEAT_TYPE_EVERY = 6;

export const eventRepeatTypeOptions = [
    {
        value: EVENT_REPEAT_TYPE_DOES_NOT_REPEAT,
        label: 'Does not repeat',
    },
    {
        value: EVENT_REPEAT_TYPE_DAILY,
        label: 'Daily',
    },
    {
        value: EVENT_REPEAT_TYPE_WEEKLY,
        label: 'Weekly',
    },
    {
        value: EVENT_REPEAT_TYPE_MONTHLY,
        label: 'Monthly',
    },
    {
        value: EVENT_REPEAT_TYPE_YEARLY,
        label: 'Yearly',
    },
    {
        value: EVENT_REPEAT_TYPE_EVERY,
        label: 'Every',
    },
];
export const eventRepeatEveryNPeriodOptions = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: '2',
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    },
];
export const eventRepeatPeriodOptions = [
    {
        value: 1,
        label: 'Day(s)',
    },
    {
        value: 2,
        label: 'Week(s)',
    },
    {
        value: 3,
        label: 'Month(s)',
    },
    {
        value: 4,
        label: 'Year(s)',
    },
];
export const eventRepeatPeriodOptionToTextMap = {
    1: 'days',
    2: 'weeks',
    3: 'months',
    4: 'years',
};


export const GOAL_TYPE_DISABLED = 0;
export const GOAL_TYPE_DAILY = 1;
export const GOAL_TYPE_WEEKLY = 2;
export const GOAL_TYPE_MONTHLY = 3;
export const GOAL_TYPE_YEARLY = 4;
export const GOAL_TYPE_PER = 5;
export const GOAL_TYPE_EVERY = 6;

export const goalTypeOptions = [
    {
        value: GOAL_TYPE_DAILY,
        label: 'Daily',
    },
    {
        value: GOAL_TYPE_WEEKLY,
        label: 'Weekly',
    },
    {
        value: GOAL_TYPE_MONTHLY,
        label: 'Monthly',
    },
    {
        value: GOAL_TYPE_YEARLY,
        label: 'Yearly',
    },
    {
        value: GOAL_TYPE_PER,
        label: 'Per',
    },
    {
        value: GOAL_TYPE_EVERY,
        label: 'Every',
    },
    {
        value: GOAL_TYPE_DISABLED,
        label: 'Disabled',
    },
];
export const goalEveryNPeriodOptions = [
    {
        value: 1,
        label: '1',
    },
    {
        value: 2,
        label: 2,
    },
    {
        value: 3,
        label: '3',
    },
    {
        value: 4,
        label: '4',
    },
    {
        value: 5,
        label: '5',
    },
    {
        value: 6,
        label: '6',
    },
    {
        value: 7,
        label: '7',
    },
    {
        value: 8,
        label: '8',
    },
    {
        value: 9,
        label: '9',
    },
    {
        value: 10,
        label: '10',
    },
];

export const GOAL_PERIOD_DAY = 1;
export const GOAL_PERIOD_WEEK = 2;
export const GOAL_PERIOD_MONTH = 3;
export const GOAL_PERIOD_YEAR = 4;

export const goalPeriodOptions = [
    {
        value: GOAL_PERIOD_DAY,
        label: 'Day(s)',
    },
    {
        value: GOAL_PERIOD_WEEK,
        label: 'Week(s)',
    },
    {
        value: GOAL_PERIOD_MONTH,
        label: 'Month(s)',
    },
    {
        value: GOAL_PERIOD_YEAR,
        label: 'Year(s)',
    },
];
