import 'react-sortable-tree/style.css';
import './sortable-tree-custom.css';

import React, {useEffect, useState, useMemo} from 'react';
// import SortableTree, { getVisibleNodeCount } from 'react-sortable-tree';
import { getVisibleNodeCount, SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import { isEmpty, get } from 'lodash';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import {
    ROUTE_CATEGORIES_NEW,
} from 'utils/routes';
import PageTitleWithButton from "utils/PageTitleWithButton";
import DataLoadingIndicator from "utils/DataLoadingIndicator";
import FillHeightContainer from "utils/FillHeightContainer";
// import GoalNode from "./GoalNode";
import {useGetGoalsTree, useSaveGoalsTree} from "api/NoteApi";
import {
    getDeepGoalNextDoDate,
    getGoalNextDoDate,
    getNewEventDate,
    getSortedGoals,
    resetNoteTasks
} from "../../utils/notes";
import Chip from "@material-ui/core/Chip";
import {openConfirmationDialog} from "../../components/utils/ConfirmationDialogContext";
import moment from "moment";
import * as noteTypes from "../../utils/noteTypes";
import * as constants from "../../utils/constants";
import CheckIcon from "@material-ui/icons/Check";
import Tooltip from "@material-ui/core/Tooltip";
import CompleteButton from "../../components/note/CompleteButton";
import GoalFrequencyChip from "./GoalFrequencyChip";
import {openNoteDialog, useNoteDispatch} from "../../components/note/NoteContext";
import CompletedDatesButton from "../../components/note/CompletedDatesButton";
import {useGetGoalsSchedule} from "../../api/NoteApi";
import NotesGridContainer from "../../components/note/NotesGridContainer";
import GoalTreeNode from "./GoalTreeNode";
import GoalTreeRenderer from "./GoalTreeRenderer";
import Button from "@material-ui/core/Button";
import {setToggle, TOGGLE_GOALS_SHOW_DISABLED} from "../../ducks/toggles";
import {useDispatch, useSelector} from "react-redux";


const isTouchDevice = !!('ontouchstart' in window || navigator.maxTouchPoints);

function getGoalNextDoDateStyles(currentDay, nextDoDate) {
    const nextDoDateUtc = moment.utc(nextDoDate).local();
    let styles = {};


    if (nextDoDateUtc.isBefore(currentDay, "day")) {
        styles = {
            color: 'rgb(97, 26, 21)',
            backgroundColor: 'rgb(253, 236, 234)',
            border: '1px solid #f44336',
        };
    } else if (nextDoDateUtc.isSame(currentDay, "day")) {
        styles = {
            color: 'rgb(30, 70, 32)',
            backgroundColor: 'rgb(237, 247, 237)',
            border: '1px solid #4caf50',
        };
    }

    //console.log('getGoalNextDoDateColor', nextDoDateUtc.isAfter(currentDay, "day"), styles);

    return styles;
}

const rowHeight = 95;
const CustomTheme = {
    rowHeight: rowHeight,
    scaffoldBlockPxWidth: 15,
    treeNodeRenderer: GoalTreeRenderer,
};

function expandTree(treeData) {
    return treeData.map((goal) => {
        return {
            ...goal,
            children: goal.children.length > 0 ? expandTree(goal.children) : goal.children,
            expanded: true,
        }
    })
}

function showDisabledGoals(treeData, willShowDisabledGoals) {
    return treeData.filter(function f(goal) {
        if (goal.children.length > 0) {
            if (goal.goalType === 0 && !willShowDisabledGoals) return false;

            goal.children = goal.children.filter(f);

            return true;
        }

        if (goal.goalType !== 0  || (goal.goalType === 0 && willShowDisabledGoals)) return true
    });
}

function Goals({match, history}) {
    const noteDispatch = useNoteDispatch();
    const dispatch = useDispatch();
    const [goalsTree, isGoalsTreeLoading, goalsTreeErrors, fetchGoalsTree] = useGetGoalsTree();
    const [goalsSchedule, isGoalsScheduleLoading, goalsScheduleErrors, fetchGoalsSchedule] = useGetGoalsSchedule();
    const [treeData, setTreeData] = useState([]);
    const [canDrag, setCanDrag] = useState(false);
    const [saveGoalsTree] = useSaveGoalsTree(treeData);
    const willShowDisabledGoals = useSelector((state) => {
        return get(state, `toggles.${TOGGLE_GOALS_SHOW_DISABLED}`, true);
    });
    const currentDay = moment();

    const todayGoals = get(goalsSchedule, 'today', []);
    const upcomingGoals = get(goalsSchedule, 'upcoming', []);
//console.log('goalsTree', goalsTree, treeData, goalsSchedule);
    useEffect(() => {
        fetchGoalsTree();
        //fetchGoalsSchedule();
    }, []);

    useEffect(() => {
        if (goalsTree !== null) {
            let adjustedGoals = expandTree(goalsTree);
            adjustedGoals = showDisabledGoals(adjustedGoals, willShowDisabledGoals);

            setTreeData(adjustedGoals);
        }
    }, [goalsTree, willShowDisabledGoals]);

    if (!goalsTree || isGoalsTreeLoading || !treeData) { // || !goalsSchedule || isGoalsScheduleLoading
        return (<DataLoadingIndicator />);
    }

    if (!isEmpty(goalsTreeErrors) || !isEmpty(goalsScheduleErrors)) {
        return <>Error</>;
    }

    const count = getVisibleNodeCount({treeData: treeData});

    return (
        <React.Fragment>
            {/*<DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>*/}
                <FillHeightContainer header={
                    <>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            minHeight: 54,
                            padding: '0 16px',
                        }}>
                            {/*<PageTitleWithButton*/}
                            {/*    pageTitle={"Goals"}*/}
                            {/*    buttonTitle={"New"}*/}
                            {/*    buttonRoute={ROUTE_CATEGORIES_NEW}*/}
                            {/*/>*/}
                            <Button size="small"

                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={() => {
                                        setCanDrag(!canDrag);
                                    }}>
                                Toggle Drag
                            </Button>
                            <Button size="small"
                                color={'primary'}
                                variant={'contained'}
                                onClick={() => {
                                    dispatch(setToggle(TOGGLE_GOALS_SHOW_DISABLED, !willShowDisabledGoals));
                                }}
                                style={{
                                    marginLeft: 6
                                }}
                            >
                                {willShowDisabledGoals ? "Hide Disabled" : "Show Disabled"}
                            </Button>
                        </div>
                    </>

                }>
                    <div style={{height:'100%'}}>
                        <div style={{
                            // height:'100%',
                            // minHeight: '100%',
                            height: count * rowHeight + 5,
                        }}>
                            <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend}>
                            <SortableTree
                                // isVirtualized={false}
                                theme={CustomTheme}
                                canDrag={canDrag}
                                nodeContentRenderer={GoalTreeNode}
                                treeData={treeData}
                                onChange={treeData => {
                                    console.log('treeData', treeData);
                                    setTreeData(treeData);
                                    saveGoalsTree();
                                }}
                                generateNodeProps={rowInfo => ({
                                    labelsRow:
                                        <div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                height: '100%',
                                            }}>
                                                <GoalFrequencyChip
                                                    size="small"
                                                    prefix={rowInfo.node.doneInPeriod + "/"}
                                                    note={rowInfo.node}
                                                    style={{
                                                        marginRight: 4,
                                                    }}
                                                />
                                                <Chip
                                                    size="small"
                                                    label={rowInfo.node.lastDoneDate ? 'L: ' + moment.utc(rowInfo.node.lastDoneDate).local().format('YYYY-MM-DD HH:mm') : "N/A"}
                                                    // HH:mm
                                                    style={{
                                                        marginRight: 4,
                                                    }}
                                                />
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                marginTop: 3,
                                                height: '100%',
                                            }}>
                                                <Chip
                                                    size="small"
                                                    label={rowInfo.node.children.length + ' children'}
                                                    // HH:mm
                                                    style={{
                                                        marginRight: 4,
                                                    }}
                                                />
                                                <Chip
                                                    size="small"
                                                    label={rowInfo.node.nextDoDate ? 'N: ' + moment.utc(rowInfo.node.nextDoDate).local().format('YYYY-MM-DD') : "N/A"}
                                                    style={{
                                                        marginRight: 4,
                                                        ...getGoalNextDoDateStyles(currentDay, rowInfo.node.nextDoDate)
                                                    }}
                                                />
                                            </div>
                                        </div>,
                                    buttons: [
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: '100%',
                                        }}>
                                            <IconButton size="small" color="inherit" onClick={() => {
                                                noteDispatch(openNoteDialog(rowInfo.node));
                                                //history.push(getGoalPath(rowInfo.node.id))
                                            }}>
                                                <EditIcon />
                                            </IconButton>

                                            {rowInfo.node.children.length === 0 && (
                                                <>
                                                    <CompletedDatesButton
                                                        note={rowInfo.node}
                                                        size="small"
                                                        color="inherit"
                                                        onUpdated={() => fetchGoalsTree()}
                                                    />
                                                    <CompleteButton
                                                        note={rowInfo.node}
                                                        icon={<CheckIcon />}
                                                        onCompleted={() => fetchGoalsTree()}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    ],
                                    style: {
                                        // width: '700px',
                                    },
                                })}
                                style={{color: 'white'}}
                                // nodeContentRenderer={GoalNode}
                            />
                            </DndProvider>
                        </div>
                        <div style={{padding: 8}}>
                            <div>Today</div>
                            {todayGoals.length > 0
                             ?
                             <>
                                 <NotesGridContainer notes={todayGoals} />
                             </>
                             :
                             <div style={{textAlign: 'center'}}>No goals today</div>
                            }
                            <div>Upcoming</div>
                            {upcomingGoals.length > 0
                             ?
                             <>
                                 <NotesGridContainer notes={upcomingGoals} />
                             </>
                             :
                             <div style={{textAlign: 'center'}}>No upcoming goals</div>
                            }
                        </div>
                    </div>
                </FillHeightContainer>
            {/*</DndProvider>*/}
        </React.Fragment>
    );
}

export default Goals;
