import React from 'react'

const NoteStateContext = React.createContext();
const NoteDispatchContext = React.createContext();

// Actions
const PREFIX = 'note/';
const OPEN_DIALOG = PREFIX + 'OPEN_DIALOG';
const CLOSE_DIALOG = PREFIX + 'CLOSE_DIALOG';
const UPSERT_START = PREFIX + 'UPSERT_START';
const UPSERT_DONE = PREFIX + 'UPSERT_DONE';

const initialState = {
    note: null,
    isDialogOpen: false,
    isUpdating: false,
    hydrated: false,
};

// Reducer
function reducer(state = initialState, action = {}) {
    if (!state.hydrated) {
        state = {...initialState, ...state, hydrated: true};
    }

    switch (action.type) {
        case OPEN_DIALOG:
            return {
                ...state,
                isDialogOpen: true,
                note: action.payload.note,
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                isDialogOpen: false,
            };
        case UPSERT_START:
            return {
                ...state,
                isUpdating: true,
            };
        case UPSERT_DONE:
            return {
                ...state,
                isUpdating: false,
            };
        default:
            return state;
    }
}

// Action Creators
export function openNoteDialog(note) {
    return {type: OPEN_DIALOG, payload: {note}};
}

export function closeNoteDialog() {
    return {type: CLOSE_DIALOG, payload: {}};
}

function NoteProvider({children}) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    console.log('NoteProvider state', state);
    return (
        <NoteStateContext.Provider value={state}>
            <NoteDispatchContext.Provider value={dispatch}>
                {children}
            </NoteDispatchContext.Provider>
        </NoteStateContext.Provider>
    )
}

function useNoteState() {
    const context = React.useContext(NoteStateContext);
    if (context === undefined) {
        throw new Error('useNoteState must be used within a NoteProvider')
    }
    return context
}

function useNoteDispatch() {
    const context = React.useContext(NoteDispatchContext);
    if (context === undefined) {
        throw new Error('useNoteDispatch must be used within a NoteProvider')
    }
    return context
}

function useNote() {
    return [useNoteState(), useNoteDispatch()]
}

export {NoteProvider, useNoteState, useNoteDispatch, useNote}
