import React, {useEffect, useState} from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { setContext } from 'apollo-link-context';
import { isEmpty } from 'lodash';

import App from './App';
import {fetchNotesPending, NotesProvider} from "./contexts/NotesContext";
import {useGetNotes} from "./api/NoteApi";


// const httpLink = createHttpLink({
//     uri: ENTRYPOINT + '/graphql',
// });

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const [token, setToken] = localStorage.getItem('token');
    const initialValue = null;
    let token = initialValue;
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('token');
        // Parse stored json or if none return initialValue
        token = item ? JSON.parse(item) : initialValue;
    } catch (error) {
        // If error also return initialValue
        //console.log(error);
        token = initialValue;
    }

    //console.log('authLink', token);
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

// const client = new ApolloClient({
//     link: authLink.concat(httpLink),
//     cache: new InMemoryCache()
// });

const Root = () => {
    const [initialized, setInitialized] = useState(false);
    const [notes, notesLoading, notesErrors, fetchNotes] = useGetNotes();

    useEffect(() => {
        fetchNotes();
        setInitialized(true);
    }, []);

    if (!initialized || notesLoading) return <p>Loading...</p>;
    if (!isEmpty(notesErrors)) return <p>Error :(</p>;

    return (
        <>
            <NotesProvider notes={notes}>
                <CssBaseline/>
                <App />
            </NotesProvider>
        </>
    )
};
export default Root;
