import moment from 'moment';

export const convertFormDateToUtc = (date) => {
    console.log('convertFormDateToUtc', date, moment(date).utc().format());

    return  moment(date).utc().format();
};

// export const convertUtcToFormDate = (date, addTime = false) => {
//     let format = 'YYYY-MM-DD';
//
//     if (addTime) {
//         format = 'YYYY-MM-DD HH:mm';
//     }
//
//     console.log('convertUtcToFormDate', date, moment.utc(date).local().format(format));
//
//     return moment.utc(date).local().format(format);
// };

export const convertUtcToFormDate = (date) => {
    console.log('convertUtcToFormDate', date, moment.utc(date).format());
    return moment.utc(date).format();
};
