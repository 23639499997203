import React, {useState} from 'react';

import {useQuery} from '@apollo/react-hooks';
import Grid from "@material-ui/core/Grid";
import Note from "components/note/Note";
import Container from "@material-ui/core/Container";
import * as noteGql from "gql/note";

import useInboxNotes from "selectors/useInboxNotes";
import {useSelector} from "react-redux";
import get from "lodash/get";

const Inbox = () => {
    const tagsFilter = useSelector((state) => {
        return get(state, "filters.tags", []);
    });
    const inboxNotes = useInboxNotes(tagsFilter);

    return (
        <>
            <div>
                {inboxNotes.length > 0 ? (
                    <>
                        <div style={{margin: 12}}>
                            <Grid container spacing={2}>
                                {inboxNotes.map((note, key) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                                        <Note note={note}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </>
                ) : (
                     <>
                         No notes
                     </>
                 )}
            </div>
        </>
    );
};

export default Inbox;
