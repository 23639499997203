import React from 'react'
import moment from 'moment';

import {Field, useFormikContext} from 'formik';
import {FormDateTimePickerField} from 'formik/FormikMaterialFields';

import {convertFormDateToUtc, convertUtcToFormDate} from 'utils/form';

export default function EventDate({priorityName, name, onChange}) {
    const { values, setFieldValue } = useFormikContext();

    console.log('EventDate values', values['eventDate'], convertFormDateToUtc(values['eventDate']), moment(values['eventDate']).format(), moment(values['eventDate']).utc().format(), moment(values['eventDate']).utc().local().format());
    return (
        <Field
            name="eventDate"
            // value={values['eventDate'] = values['eventDate'] === null ? null : convertFormDateToUtc(values['eventDate'])}
            component={FormDateTimePickerField}
            onChange={(date) => {
                console.log('EventDate onchange', date, convertUtcToFormDate(date));
                setFieldValue('eventDate', convertUtcToFormDate(date));
            }}
            label="Date & Time"/>
    )
}
