import React from 'react';
import isArray from 'lodash/isArray';

import FormHelperText from '@material-ui/core/FormHelperText';

function ResponseErrors({errors}) {
    return (
        <React.Fragment>
            {isArray(errors) && errors.length > 0 ? (
                <ul style={{flex: 1, paddingLeft: 16, paddingRight: 16, margin: 0, listStyle: 'none', wordWrap: 'break-word',}}>
                    {errors.map((error, errorIndex) => (
                        <li key={errorIndex}><FormHelperText style={{marginTop: 0}} error>{error}</FormHelperText></li>
                    ))}
                </ul>
            ) : null}
        </React.Fragment>
    );
}
export default ResponseErrors;