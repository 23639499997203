import React, {useState, useEffect, useRef} from "react";
import {get, isObject} from 'lodash';

import { styled } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import {useTheme} from "@material-ui/core/styles";

import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import {MaterialTextField, MaterialSelectField, MaterialDatePickerField, MaterialDateTimePickerField} from './MaterialFields';
import TagsSelect from 'components/tag/TagsSelect';
import {convertUtcToFormDate} from "utils/form";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledFormControl = styled(FormControl)`
  && {
    margin-top: ${props => props.theme.spacing(1)}px;
    margin-bottom: ${props => props.theme.spacing(1)}px;
  }
`;

export function FormMaterialSelectField({ field, options, form: { touched, errors }, ...props }) {
    return (
        <MaterialSelectField
            helperText={get(touched, field.name) ? get(errors, field.name) : ""}
            error={get(touched, field.name) && Boolean(get(errors, field.name))}
            options={options}
            {...field}
            {...props}
        />
    );
}

export function FormTagsSelectField({ label, field, form, ...props }) {
    //console.log('FormTagsSelectField', label, field, form, props);

    return (
        <TagsSelect
            label={label}
            onChange={(newValues) => {
                form.setFieldValue(field.name, newValues);
            }}
            values={field.value}
        />
    );
}

export function MaterialRadioGroup({ label, field, form: { touched, errors }, ...props }) {
    //const theme = useTheme();
    //console.log('MaterialRadioGroup', field, props);

    return (
        <FormControl
            component="fieldset"
            error={touched[field.name] && Boolean(errors[field.name])}
        >
            <RadioGroup
                {...field}
                {...props}
            >
                <FormControlLabel value="0" control={<Radio color="primary" />} label={<b>Paprastas produktas.</b>} />
                {/*<FormControlLabel value="1" control={<Radio color="primary" />} label={<React.Fragment><b>Produktas su variantais.</b> Kai produktas turi skirtingus dydžius ar spalvas.</React.Fragment>} />*/}
                {/*<FormControlLabel value="2" control={<Radio color="primary" />} label={<React.Fragment><b>Produktų grupė ar rinkinys.</b> Kai produktas susideda iš kelių paprastų produktų.</React.Fragment>} />*/}
            </RadioGroup>
            {/*<FormHelperText>You can display an error</FormHelperText>*/}
        </FormControl>
    );
}

export function MaterialRadioGroupButton({ label, field, form: { touched, errors }, options, ...props }) {
    return (
        <FormControl
            fullWidth
            component="fieldset"
            error={touched[field.name] && Boolean(errors[field.name])}
        >
            <RadioGroup
                {...field}
                {...props}
            >
                {options.map((option, index) => (
                    <label key={index}>
                        <Button variant="outlined" component="span" fullWidth style={{
                            textTransform: 'none',
                            paddingLeft: 0,
                            paddingRight: 8,
                            justifyContent: 'start',
                            marginBottom: 16,
                        }}>
                            <Radio value={option.value} />
                            <div style={{
                                textAlign: 'left',
                            }}>
                                {option.label}
                            </div>
                        </Button>
                    </label>
                ))}
            </RadioGroup>
            {/*<FormHelperText>You can display an error</FormHelperText>*/}
        </FormControl>
    );
}

export function FormCheckboxField({ label = null, field, form: { touched, errors, setFieldValue }, ...props }) {
    //const theme = useTheme();
    console.log('FormCheckboxField', field);
    return (
        <>
        {!label ? (
            <Checkbox
                {...field}
                {...props}
                checked={field.value}
                value={field.name}
                onChange={(e, value) => {
                    setFieldValue(field.name, value);
                }}
            />
        ) : (
            <FormControlLabel
                control={
                    <Checkbox
                        {...field}
                        {...props}
                        checked={field.value}
                        value={field.name}
                        onChange={(e, value) => {
                            setFieldValue(field.name, value);
                        }}
                    />
                }
                label={label}
            />
        )}
        </>
    );
}


export const FormDateTimePickerField = ({ field, form: { touched, errors, setFieldValue, setFieldError, setFieldTouched }, ...props }) => {
    const currentError = errors[field.name];

    console.log('FormDateTimePickerField', field, get(touched, field.name), Boolean(get(errors, field.name)));
    return (
        <>
            <MaterialDateTimePickerField
                // helperText={currentError}
                // error={Boolean(currentError)}
                helperText={get(touched, field.name) ? get(errors, field.name) : ""}
                error={get(touched, field.name) && Boolean(get(errors, field.name))}
                format="YYYY-MM-DD HH:mm"
                name={field.name}
                value={field.value}
                onError={(error, value) => {
                    console.log('1. onError', error, value);
                    //setFieldTouched(field.name, true);
                    //setFieldValue(field.name, null, true);


                    //setFieldError(field.name, error);
                    //setFieldTouched(field.name, true);
                }}
                // mask={value => (console.log('vaue', value) && value ? [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/] : [])}
                // onChange={date => {
                //     console.log('2. onChange', date, date instanceof Date);
                //
                //     setFieldValue(field.name, date, true);
                // }}
                {...props}
            />
        </>
    );
};


export const FormDatePickerField = ({ field, form: { touched, errors, setFieldValue, setFieldError, setFieldTouched }, ...props }) => {
    const currentError = errors[field.name];

    console.log('FormDatePickerField', field, get(touched, field.name), Boolean(get(errors, field.name)));
    return (
        <>
            <MaterialDatePickerField
                // helperText={currentError}
                // error={Boolean(currentError)}
                helperText={get(touched, field.name) ? get(errors, field.name) : ""}
                error={get(touched, field.name) && Boolean(get(errors, field.name))}
                format="YYYY-MM-DD"
                name={field.name}
                value={field.value}
                onChange={(date) => {
                    console.log('FormDatePickerField onchange', date, convertUtcToFormDate(date));
                    setFieldValue('dueDate', convertUtcToFormDate(date));
                }}
                onError={(error, value) => {
                    console.log('1. onError', error, value);

                    if (value === 'Invalid date') {
                       setFieldTouched(field.name, true);
                       setFieldValue(field.name, null, true);
                    }
                    //setFieldTouched(field.name, true);
                    //setFieldValue(field.name, null, true);


                    //setFieldError(field.name, error);
                    //setFieldTouched(field.name, true);
                }}
                // mask={value => (console.log('vaue', value) && value ? [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/] : [])}
                // onChange={date => {
                //     console.log('2. onChange', date, date instanceof Date);
                //
                //     setFieldValue(field.name, date, true);
                // }}
                {...props}
            />
        </>
    );
};

export function FormMaterialTextField({ field, form: { touched, errors }, ...props }) {
    return (
        <MaterialTextField
            helperText={get(touched, field.name) ? get(errors, field.name) : ""}
            error={get(touched, field.name) && Boolean(get(errors, field.name))}
            {...field}
            {...props}
        />
    );
}

export function FormMaterialTags({ field, form: { touched, errors }, ...props }) {
    return (
        <MaterialTextField
            helperText={get(touched, field.name) ? get(errors, field.name) : ""}
            error={get(touched, field.name) && Boolean(get(errors, field.name))}
            {...field}
            {...props}
        />
    );
}

const StyledTableTextField = styled(TextField)`
  && {
    margin-top: 0;
    margin-bottom: 0;

    & .smallerInput {
      padding-top: ${props => props.theme.spacing(1)}px;;
      padding-bottom: ${props => props.theme.spacing(1)}px;;
    }
  }
`; ////${props => props.theme.spacing.unit}px;

export function MaterialTableTextField({ field, form: { touched, errors }, ...props }) {
    const theme = useTheme();

    return (
        <StyledTableTextField
            theme={theme}
            helperText={get(touched, field.name) ? get(errors, field.name) : ""}
            error={get(touched, field.name) && Boolean(get(errors, field.name))}
            variant="outlined"
            margin="dense"
            InputProps={{
                classes: {
                    input: 'smallerInput',
                },
            }}
            {...field}
            {...props}
        />
    );
}

export function MaterialMultiSelectChip({ options, label, field, form: { touched, errors }, fullWidth, ...props }) {
    const theme = useTheme();
    const [labelWidth, setLabelWidth] = useState(0);
    const labelEl = useRef(null);

    useEffect(() => {
        //console.log('labelEl', labelEl, labelEl.current, labelEl.current.offsetWidth);
        setLabelWidth(labelEl.current.offsetWidth);
    }, []);

    // const measuredRef = useCallback(labelEl => {
    //     console.log('labelEl', labelEl, labelEl.current);
    //     if (labelEl !== null && labelEl.current !== null) {
    //         console.log('labelEl2', labelEl, labelEl.current, labelEl.current.offsetWidth);
    //         setLabelWidth(labelEl.current.offsetWidth);
    //     }
    // }, []);

    return (
        <StyledFormControl
            theme={theme}
            variant="outlined"
            fullWidth={fullWidth}
        >
            {/*<RootRef rootRef={labelEl}>*/}
                <InputLabel {...props} htmlFor="select-multiple-chip">{label}</InputLabel>
            {/*</RootRef>*/}
            <Select
                multiple
                input={<OutlinedInput
                    id="select-multiple-chip"
                    labelWidth={labelWidth}
                />}
                renderValue={selected => {
                    const selectedOptions = options.filter(option => {
                        return selected.indexOf(option.id) > -1;
                    });

                    return (
                        <div>
                            {selectedOptions.map(option => (
                                <Chip key={option.id} label={option.title} />
                            ))}
                        </div>
                    )
                }}
                {...field}
                {...props}
            >
                {options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </StyledFormControl>
    );
}

