import React, {useEffect, useState} from 'react';
import {Formik, Form, Field, FieldArray, ErrorMessage, FastField, useFormikContext} from 'formik';
import * as Yup from 'yup';
import uuidv4 from 'uuid/v4';
import {useMutation} from '@apollo/react-hooks';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Grid from '@material-ui/core/Grid';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ImageIcon from '@material-ui/icons/Image';
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import FormHelperText from '@material-ui/core/FormHelperText';

import {FormMaterialTextField, FormCheckboxField} from "formik/FormikMaterialFields";
import {gql} from "apollo-boost";
import Note, {Task} from "entities/Note";
import * as noteTypes from 'utils/noteTypes';
import NoteTypes from 'components/note/NoteTypes';
import Size from 'components/noteCompose/Size';
import TaskPriorities from 'components/noteCompose/TaskPriorities';
import TaskDueDate from 'components/noteCompose/TaskDueDate';
import EventDate from 'components/noteCompose/EventDate';
import EventRepeat from 'components/noteCompose/EventRepeat';
import GoalFrequency from 'components/noteCompose/GoalFrequency';
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import {FormTagsSelectField} from "../../formik/FormikMaterialFields";

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "none",
    //padding: grid,
    width: '100%',
});
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    //padding: grid * 2,
    //margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "none",

    // styles we need to apply on draggables
    ...draggableStyle
});

function NoteForm() {
    const {setFieldValue, values} = useFormikContext();
    const onDragEnd = (result, arrayHelpers) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        //console.log('onDragEnd', result.source.index, result.destination.index, result);

        if (result.source.index !== result.destination.index) {
            arrayHelpers.move(result.source.index, result.destination.index);
        }
    };

    return (
        <React.Fragment>
            {/*<FieldArray name="completed" component={renderCompleted} />*/}
            {/*{ note.type !== noteTypes.INBOX && note.type !== noteTypes.REFERENCE && note.type !== noteTypes.SOMEDAY &&*/}
            {/*  <EditTasks change={change} completedDates={completedDates} />*/}
            {/*}*/}
            {/*{ (note.type === noteTypes.INBOX || note.type === noteTypes.REFERENCE || note.type === noteTypes.SOMEDAY) &&*/}
            {/*<CardContent className={classes.cardContent}>*/}
            {/*  <Field name="text" required component={TextArea}/>*/}
            {/*</CardContent>*/}
            {/*}*/}
            <div style={{padding: "0 8px 8px"}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FastField name="title"
                               label="Title"
                               fullWidth
                               component={FormMaterialTextField}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/*<FastField name="tags"*/}
                        {/*           label="Kategorijos"*/}
                        {/*           fullWidth*/}
                        {/*           component={FormTagsSelectField}*/}
                        {/*/>*/}
                    </Grid>
                    <FieldArray name="tasks"
                                render={arrayHelpers => {
                                    return (
                                        <React.Fragment>
                                            {values.tasks && values.tasks.length > 0 ? (
                                                <DragDropContext onDragEnd={(result) => {
                                                    onDragEnd(result, arrayHelpers);
                                                }}>
                                                    <Droppable droppableId="droppable">
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                style={getListStyle(snapshot.isDraggingOver)}
                                                            >
                                                                <List dense={true}>
                                                                    {values.tasks.map((task, taskIndex) => (
                                                                        <Draggable key={taskIndex} draggableId={'dragganle-' + taskIndex} index={taskIndex}>
                                                                            {(provided, snapshot) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}

                                                                                    key={taskIndex}
                                                                                    style={getItemStyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}
                                                                                >
                                                                                    <ListItem
                                                                                        style={{
                                                                                            paddingLeft: 0,
                                                                                            paddingRight: 0,
                                                                                        }}
                                                                                    >
                                                                                        <IconButton size={'small'} {...provided.dragHandleProps}>
                                                                                            <DragHandleIcon/>
                                                                                        </IconButton>
                                                                                        <Field
                                                                                            name={`tasks.${taskIndex}.completed`}
                                                                                            component={FormCheckboxField}
                                                                                            label={null}
                                                                                        />
                                                                                        <FastField
                                                                                            name={`tasks.${taskIndex}.text`}
                                                                                            component={FormMaterialTextField}
                                                                                            margin={'dense'}
                                                                                            fullWidth
                                                                                        />
                                                                                        <IconButton onClick={() => arrayHelpers.remove(taskIndex)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </ListItem>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </List>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            ) : null}

                                            <Grid item xs={12}>
                                                <Button variant="outlined" onClick={() => arrayHelpers.push(new Task())}>
                                                    Add a sub-task
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    )
                                }}
                    />
                    <Grid item xs={12}>
                        <NoteTypes/>
                    </Grid>
                    {/*{ values.type !== noteTypes.INBOX ?*/}
                    {/*  <Grid item xs={6}>*/}
                    {/*    <Groups/>*/}
                    {/*  </Grid> : null*/}
                    {/*}*/}
                    {(values.type === noteTypes.TASK || values.type === noteTypes.GOAL) ?
                        <Grid item xs={12}>
                            <Size/>
                        </Grid> : null
                    }
                    {values.type === noteTypes.TASK ?
                        <Grid item xs={6}>
                            <TaskPriorities/>
                        </Grid> : null
                    }

                    {values.type === noteTypes.TASK ?
                        <Grid item xs={12}>
                            <TaskDueDate/>
                        </Grid> : null
                    }
                    {values.type === noteTypes.EVENT ?
                        <Grid item xs={12} md={6}>
                            <EventDate/>
                        </Grid> : null
                    }
                    {values.type === noteTypes.EVENT ?
                        <Grid item xs={12} md={6}>
                            <EventRepeat/>
                        </Grid> : null
                    }
                    {values.type === noteTypes.GOAL ?
                        <Grid item xs={12} md={6}>
                            <GoalFrequency/>
                        </Grid> : null
                    }
                </Grid>
            </div>
        </React.Fragment>
    );
}

export default NoteForm;
