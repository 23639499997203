import React from 'react';

import Typography from '@material-ui/core/Typography';

function PageTitle({title, gutterBottom = false, ...props}) {
    return (
        <Typography component="h1" variant="h6" gutterBottom={gutterBottom} {...props}>
            {title}
        </Typography>
    );
}
export default PageTitle;