import React from 'react';
import moment from 'moment';
import {get, find} from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArchiveIcon from '@material-ui/icons/Archive';
import CheckIcon from '@material-ui/icons/Check';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Chip from '@material-ui/core/Chip';

import {useNoteDispatch, openNoteDialog} from 'components/note/NoteContext';
import useUpdateNote from 'components/note/useUpdateNote';
import {useConfirmationDialogDispatch, openConfirmationDialog} from 'components/utils/ConfirmationDialogContext';
import {useCompletedDatesDialogDispatch, openCompletedDatesDialog} from 'components/utils/CompletedDatesDialogContext';
import * as noteTypes from "utils/noteTypes";
import * as constants from "utils/constants";
import {getNotePriority, getGoalNextDoDate, getNewEventDate, resetNoteTasks} from 'utils/notes';

import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import {Field} from "formik";
import {FormCheckboxField, FormMaterialTextField} from "formik/FormikMaterialFields";
import Checkbox from "@material-ui/core/Checkbox";
import {ListItemText} from "@material-ui/core";
import CompletedDatesButton from "./CompletedDatesButton";

const useStyles = makeStyles(theme => ({
    card: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        whiteSpace: 'pre-line',
        wordWrap: 'break-word',
        overflow: 'hidden',
        maxHeight: 140,
        padding: '12px 12px 8px',
        lineHeight: '20px',
    },
    cardActions: {
        padding: '4px 0',
        // position: 'absolute',
        // bottom: 0,
        // width: '100%',
        // visibility: 'hidden',
        background: 'white',
        borderTop: '1px solid #f1f0f0',
    },
    chip: {
        margin: '2px',
    },
}));

export default function Note({note}) {
    const confirmationDialogDispatch = useConfirmationDialogDispatch();
    const dispatch = useNoteDispatch();
    // const [updateNote, updateNoteVariables] = useUpdateNote();
    // const {loading: loadingTags, error: errorTags, data: dataTags} = useQuery(tagGql.GET_TAGS);
    const classes = useStyles();

    const handleClickOpen = () => {
        console.log('handleClickOpen');
        dispatch(openNoteDialog(note));
    };

    return (
        <>
            <Card onClick={handleClickOpen}>
                {note.title ? (
                    <CardHeader
                        titleTypographyProps={{
                            variant: 'h6',
                            style: {fontSize: '1.05rem'},
                        }}
                        style={{padding: '8px 12px 0 12px'}}
                        title={note.title}
                    />
                ) : null}
                {note.tasks && note.tasks.length > 0 ? (
                    <>
                        <List dense>
                            {note.tasks.map((task, index) => (
                                <ListItem
                                    key={index}
                                    style={{
                                        padding: 0,
                                    }}
                                >
                                    <Checkbox
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        checked={task.completed}
                                        onChange={(e, value) => {
                                            const newTasks = [
                                                ...note.tasks.slice(0, index),
                                                {
                                                    ...task,
                                                    completed: value
                                                },
                                                ...note.tasks.slice(index + 1)
                                            ];

                                            // updateNote({
                                            //     ...note,
                                            //     tasks: newTasks,
                                            // });
                                        }}
                                    />
                                    <ListItemText primary={task.text} />
                                </ListItem>
                            ))}
                        </List>
                    </>
                ) : null}
                {note.text ? (
                    <CardContent>
                        {note.text}
                    </CardContent>
                ) : null}
                <div>
                    {note.type !== noteTypes.INBOX ? (
                        <div style={{
                            padding: '0 9px',
                            flexGrow: 1,
                        }}>
                            {/*{note.type !== noteTypes.INBOX*/}
                            {/*    ?*/}
                            {/*    <span><Chip className={classes.chip} classes={{label: classes.chipLabel}}*/}
                            {/*                label={noteGroupsById[note.groupId].name}/></span>*/}
                            {/*    :*/}
                            {/*    null*/}
                            {/*}*/}
                            { note.type === noteTypes.EVENT ?
                                <span>
                            <Chip className={classes.chip} classes={{label: classes.chipLabel}}
                                  label={moment.utc(note.eventDate).local().format('YYYY-MM-DD HH:mm')}/>
                        </span>
                                : null
                            }
                            { note.type === noteTypes.TASK ?
                                <span>
                                <Chip className={classes.chip} label={'P:' + getNotePriority(note)}/>
                                    { note.dueDate !== null ?
                                        <Chip className={classes.chip} classes={{label: classes.chipLabel}}
                                              label={'Due: ' + moment.utc(note.dueDate).local().format('YYYY-MM-DD')}/> : null
                                    }
                            </span>
                                : null
                            }
                            { note.type === noteTypes.GOAL ?
                                <span>
                            {/*<Chip label={'E:' + getGoalFrequencyPerDay(note)} />*/}
                                    { note.completedDates.length > 0 ?
                                        <Chip className={classes.chip} classes={{label: classes.chipLabel}}
                                              label={'Last done: ' + moment.utc(note.completedDates[0].completedAt).local().format('YYYY-MM-DD HH:mm')}/>
                                        :
                                        <Chip className={classes.chip} classes={{label: classes.chipLabel}} label="Never done"/>
                                    }
                                    <Chip className={classes.chip} classes={{label: classes.chipLabel}}
                                          label={'Best done at: ' + getGoalNextDoDate(note, 30).local().format('YYYY-MM-DD')}/>
                        </span>
                                : null
                            }
                        </div>
                    ) : null }
                </div>
                {/*{note.tags && note.tags.length > 0 ? (*/}
                {/*    <>*/}
                {/*        {loadingTags ?*/}
                {/*         ("loading")*/}
                {/*         :*/}
                {/*         note.tags.map((tag, index) => {*/}
                {/*            const tagsArray = get(dataTags, 'tags', []);*/}
                {/*            const tagObject = find(tagsArray, ['id', tag]);*/}

                {/*            if (typeof tagObject !== undefined) {*/}
                {/*                return (*/}
                {/*                    <Chip*/}
                {/*                        key={index}*/}
                {/*                        className={classes.chip}*/}
                {/*                        classes={{label: classes.chipLabel}}*/}
                {/*                        label={get(tagObject, 'name')}*/}
                {/*                    />*/}
                {/*                );*/}
                {/*            } else {*/}
                {/*                return null;*/}
                {/*            }*/}
                {/*        })}*/}
                {/*    </>*/}
                {/*) : null}*/}
                <CardActions
                    disableSpacing
                    style={{
                        display: 'flex',
                        padding: 4
                    }}
                >
                    <span style={{flex: 1}}></span>
                    {/*//&& isAllNoteTasksCompleted(note)    // && note.eventRepeatType === constants.EVENT_REPEAT_TYPE_DOES_NOT_REPEAT*/}
                    {(note.type === noteTypes.GOAL || (note.type === noteTypes.EVENT && note.eventRepeatType !== constants.EVENT_REPEAT_TYPE_DOES_NOT_REPEAT)) ?
                        (
                            <>
                                <Tooltip title="Complete">
                                    <IconButton onClick={(e) => {
                                        e.stopPropagation();

                                        confirmationDialogDispatch(openConfirmationDialog("Confirmation", "Ar you sure?",() => {
                                            let newCompletedDate = moment.utc().format();
                                            let newCompletedDates = [
                                                newCompletedDate,
                                                ...note.completedDates,
                                            ].sort().reverse();

                                            const newNoteTasks = note.tasks.map((task) => {
                                                return {
                                                    ...task,
                                                    completed: false,
                                                };
                                            });
                                            note = resetNoteTasks(note);

                                            let newEventDate = note.eventDate;
                                            if (note.type === noteTypes.EVENT && note.eventRepeatType !== constants.EVENT_REPEAT_TYPE_DOES_NOT_REPEAT) {
                                                newEventDate = getNewEventDate(note);
                                            }

                                            // updateNote({
                                            //     ...note,
                                            //     completedDates: newCompletedDates,
                                            //     eventDate: newEventDate,
                                            //     tasks: newNoteTasks,
                                            // });
                                        }));
                                    }}>
                                        <CheckIcon/>
                                    </IconButton>
                                </Tooltip>
                                <CompletedDatesButton note={note} />
                            </>
                        ) : null
                    }
                    {(note.type === noteTypes.TASK || note.type === noteTypes.EVENT || note.type === noteTypes.GOAL) ?
                        (
                            <Tooltip title="Archive">
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();

                                    confirmationDialogDispatch(openConfirmationDialog("Confirmation", "Ar you sure?",() => {
                                        // updateNote({
                                        //     ...note,
                                        //     archivedAt: moment.utc().format(),
                                        // });
                                    }));
                                }}>
                                    <ArchiveIcon/>
                                </IconButton>
                            </Tooltip>
                        ) : null
                    }
                    <Tooltip title="Delete">
                        <IconButton onClick={(e) => {
                            e.stopPropagation();

                            confirmationDialogDispatch(openConfirmationDialog("Confirmation", "Ar you sure?",() => {
                                // updateNote({
                                //     ...note,
                                //     deletedAt: moment.utc().format(),
                                // });
                            }));
                        }}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
        </>
    );
}
