import React, {useEffect, useState} from 'react';
import get from 'lodash/get';

import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import {useCompletedDatesDialogState, useCompletedDatesDialogDispatch, closeCompletedDatesDialog} from 'components/utils/CompletedDatesDialogContext';
import CompletedDatesFormContainer from 'components/utils/CompletedDatesFormContainer';
import CompletedDatesForm from 'components/utils/CompletedDatesForm';
import ProgressSubmitButton from "formik/ProgressSubmitButton";
import {convertUtcToLocal} from "../../utils/dates";
import CompleteButton from "../note/CompleteButton";
import {useDeleteCompletedDate} from "../../api/NoteApi";
import ProgressIconButton from "../../utils/ProgressIconButton";
import {updateNote} from "./CompletedDatesDialogContext";
import {openConfirmationDialog, useConfirmationDialogDispatch} from "./ConfirmationDialogContext";
import {useDispatch, useSelector} from "react-redux";
import {setToggle, TOGGLE_COMPLETED_SHOW_ONLY_WITH_NOTES, TOGGLE_GOALS_SHOW_DISABLED} from "../../ducks/toggles";

function CompletedDatesDialog() {
    const dispatch = useDispatch();
    const completedDatesState = useCompletedDatesDialogState();
    const completedDatesDispatch = useCompletedDatesDialogDispatch();
    const confirmationDialogDispatch = useConfirmationDialogDispatch();
    const [completedDateId, setCompletedDateId] = useState(null);
    const [newNote, isLoading, deleteCompletedDate] = useDeleteCompletedDate(get(completedDatesState, "note.id"), completedDateId);
    const showCompletedOnlyWithNotes = useSelector((state) => {
        return get(state, `toggles.${TOGGLE_COMPLETED_SHOW_ONLY_WITH_NOTES}`, true);
    });

    const handleClose = () => {
        completedDatesDispatch(closeCompletedDatesDialog());
    };

    const updateNoteState = (newNote) => {
        completedDatesDispatch(updateNote(newNote));
    }

    useEffect(() => {
        if (newNote) {
            updateNoteState(newNote);
        }
    }, [newNote]);
    let completedDates = completedDatesState?.note?.completedDates || [];
    completedDates = completedDates.filter(completedDate => {
        return !showCompletedOnlyWithNotes || (showCompletedOnlyWithNotes && (get(completedDate, "comment", "") || "").length > 0)
    });

    return (
        <React.Fragment>
            {completedDatesState.isOpen ? (
                // <CompletedDatesFormContainer
                //     note={completedDatesState.note}
                //     onChanged={handleClose}
                // >
                //     {({loading}) => {
                //         //console.log('isLoading', loading, completedDatesState.note);
                //         return (
                            <React.Fragment>
                                <Dialog
                                    fullWidth={true}
                                    maxWidth={'sm'}
                                    open={completedDatesState.isOpen}
                                    onClose={() => {
                                        handleClose();
                                    }}
                                >
                                    <DialogTitle>
                                        Completed Dates
                                        <Button size="small"
                                                color={'primary'}
                                                variant={'contained'}
                                                onClick={() => {
                                                    dispatch(setToggle(TOGGLE_COMPLETED_SHOW_ONLY_WITH_NOTES, !showCompletedOnlyWithNotes));
                                                }}
                                                style={{
                                                    marginLeft: 6
                                                }}
                                        >
                                            {showCompletedOnlyWithNotes ? "Show all" : "Show only with notes"}
                                        </Button>
                                    </DialogTitle>
                                    <DialogContent style={{padding: 0}}>
                                        {completedDates.length > 0 ? (
                                            <List disablePadding>
                                                {completedDates.map((completedDate) => (
                                                    <ListItem key={completedDate.id}>
                                                        <ListItemText
                                                            primary={convertUtcToLocal(completedDate.completedAt)}
                                                            secondary={completedDate.comment}
                                                            secondaryTypographyProps={{
                                                                style: {
                                                                    whiteSpace: 'pre-line'
                                                                }
                                                            }}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <CompleteButton
                                                                note={completedDatesState.note}
                                                                completedDate={completedDate}
                                                                iconEdge="end"
                                                                iconSize='medium'
                                                                icon={<EditIcon />}
                                                                onCompleted={(newNote) => {
                                                                    updateNoteState(newNote);
                                                                }}
                                                            />

                                                            <ProgressIconButton
                                                                loading={isLoading}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();

                                                                    confirmationDialogDispatch(openConfirmationDialog("Confirmation", "Ar you sure?",() => {
                                                                        setCompletedDateId(completedDate.id);
                                                                        deleteCompletedDate();
                                                                    }));
                                                                }}
                                                                size={'medium'}
                                                                buttonColor={'inherit'}
                                                                icon={<DeleteIcon />}
                                                                edge={'end'}
                                                                tooltipTitle={"Complete"}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : null}
                                    </DialogContent>
                                    <DialogActions style={{display: 'flex'}}>
                                        <Button onClick={handleClose} color="primary">
                                            Cancel
                                        </Button>
                                        <span style={{flex: 1}}/>
                                        {/*<ProgressSubmitButton*/}
                                        {/*    loading={loading}*/}
                                        {/*    text={"Save"}*/}
                                        {/*    variant={'text'}*/}
                                        {/*/>*/}
                                    </DialogActions>
                                </Dialog>
                            </React.Fragment>
            //             )
            //         }}
            //     </CompletedDatesFormContainer>
            ) : null}
        </React.Fragment>
    );
}
export default CompletedDatesDialog;
