import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

function DataLoadingIndicator({value}) {
    return (
        <div>
            <CircularProgress />
        </div>
    );
}
export default DataLoadingIndicator;