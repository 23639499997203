import {useState, useEffect} from 'react';
import axios from 'axios';
import qs from 'qs';
import isEmpty from "lodash/isEmpty";

export const WEB_SERVER_URL = process.env.REACT_APP_WEB_SERVER_URL;
export const API_SERVER_URL = process.env.REACT_APP_API_SERVER_URL;
export const API_PATH = process.env.REACT_APP_API_PATH;
export const API_URL = API_SERVER_URL + API_PATH;

export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_PUT = 'put';


export function getAuthTokenHeaders() {
    const initialValue = null;
    let token = initialValue;
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem('token');
        // Parse stored json or if none return initialValue
        token = item ? JSON.parse(item) : initialValue;
    } catch (error) {
        // If error also return initialValue
        //console.log(error);
        token = initialValue;
    }

    return token ? {
        'Authorization':  token ? `Bearer ${token}` : "",
    } : {};
}

// const userShema = new schema.Entity('users');
// const arrayOfUsersShema = new schema.Array(userShema);
export function makeRequest(method, url, data = null, additionalHeaders = {}) {
    const authTokenHeaders = getAuthTokenHeaders();
//console.log('env', API_SERVER_URL, API_PATH, WEB_SERVER_URL, url);
    return axios.request({
        url: url,
        method: method,
        headers: {
            ...authTokenHeaders,
            ...additionalHeaders
        },
        data: data,
    }).then((response) => {
        let errors = [];
        let success = 1;

        if (response.status >= 200 && response.status < 300) {
        } else {
            //todo[as]: handle adding errors
            success = 0;
            if ('errors' in response && Array.isArray(response['errors'])) {
                errors = response['errors'];
            }
        }

        return {success: success, data: response.data, headers: response.headers, errors: errors};
    }).catch((error, asd1, asd2) => {
        if (axios.isCancel(error)) {
            //do nothing, request cancelled
            return {success: -1, errors: []};
        } else if (typeof error.response === 'object') {
            const response = error.response;
            const responseData = response.data;

            if (response.status === 403) {
                return {success: 0, errors: ["Neturite reikiamų teisių atlikti šį veiksmą."]};
            } else {
                let errors = [];
                if (typeof responseData === 'object' && 'errors' in responseData && Array.isArray(responseData['errors'])) {
                    errors = responseData['errors'];
                } else if (typeof responseData === 'object' && 'error' in responseData && typeof responseData['error'] === 'string') {
                    errors = [responseData['error']];
                } else if (typeof responseData === 'object' && 'error' in responseData && typeof responseData['error'] === 'object' && 'message' in responseData['error']) {
                    errors = [responseData['error']['message']];
                } else if (typeof responseData === 'object' && 'message' in responseData) {
                    errors = [responseData['message']];
                }

                if (errors.length === 0) {
                    errors.push('Sistemos klaida');
                }

                return {success: 0, errors: errors};
            }
        } else {
            // 500?
            return {success: 0, errors: ["Nepavyko prisijungti prie sistemos"]};
        }
    });
}

export function useMakeRequestArrayResponse(method, url, dataToSend = null, additionalHeaders = {}, queryParams = {}) {
    const { data, totalCount, isLoading, errors, doRequest } = useMakeRequest(method, url, dataToSend, additionalHeaders, queryParams);

    return [ data, totalCount, isLoading, errors, doRequest ];
}

export function useMakeRequest(method, url, dataToSend = null, additionalHeaders = {}, queryParams = {}) {
    const [data, setData] = useState(null);
    const [totalCount, setTotalCount] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [startRequest, setStartRequest] = useState(0);
    const queryString = !isEmpty(queryParams) ? '?' + qs.stringify(queryParams, { skipNulls: true }) : '';

    useEffect(() => {
        let didCancel = false;

        if (startRequest) { //on init it's false
            makeRequest(method, url + queryString, dataToSend, additionalHeaders).then((response) => {
                if (!didCancel) {
                    if (response.success === 1) {
                        if ("x-total-count" in response.headers) {
                            setTotalCount(parseInt(response.headers["x-total-count"], 10));
                        }

                        setData(response.data);
                    } else {
                        setErrors(response.errors);
                    }

                    setIsLoading(false);
                }
            });
        }

        return () => {
            didCancel = true;
        };
    }, [startRequest]);

    const doRequest = () => {
        setErrors([]);
        setIsLoading(true);
        //setData(null);
        setStartRequest(startRequest+1);
    };

    return { data, setData, totalCount, isLoading, errors, doRequest };
}

export function useEndpoint(method, url, dataToSend) {
    const { data, setData, isLoading, errors, doRequest } =  useMakeRequest(method, url, dataToSend);

    return [ data, setData, isLoading, errors, doRequest ];
}
