import React from 'react';

import { useFormikContext, Formik, Form, Field } from 'formik';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

function ProgressSubmitButton({loading, disabled = false, text, onClick, type="button", variant="contained", color="primary"}) {
    const { values, submitForm } = useFormikContext();

    return (
        <div style={{
            display: 'inline-block',
            position: 'relative',
        }}>
            <Button
                variant={variant}
                color={color}
                disabled={loading || disabled}
                onClick={submitForm}
                type={type}
            >
                {text}
            </Button>
            {loading && <CircularProgress size={24} style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
            }} />}
        </div>
    );
}
export default ProgressSubmitButton;
