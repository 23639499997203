import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import {FormMaterialTextField} from 'formik/FormikMaterialFields';

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .required('This field can not be empty'),
    password: Yup.string()
        .required('This field can not be empty'),
});

function LoginForm({onRequestUserLogin, isSubmitting}) {
    return (
        <Card>
            <CardContent>
                <Formik
                    initialValues={{ email: '', password: '' }}
                    validationSchema={LoginSchema}
                    onSubmit={(values) => {
                        onRequestUserLogin(values.email, values.password);
                    }}
                >
                    {() => (
                        <Form autoComplete="on">
                            <Field
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                autoComplete="username email"
                                margin="normal"
                                component={FormMaterialTextField}
                            />
                            <Field
                                id="password"
                                type="password"
                                name="password"
                                label="Password"
                                fullWidth
                                autoComplete={'current-password'}
                                margin="normal"
                                component={FormMaterialTextField}
                            />
                            <Button fullWidth variant="contained" color="primary" type="submit" disabled={isSubmitting} style={{
                                marginTop: 16
                            }}>
                                Login
                            </Button>
                        </Form>
                    )}
                </Formik>
            </CardContent>
        </Card>
    );
}

export default LoginForm;
