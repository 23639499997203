import {get, isArray} from "lodash";

export const filterByTags = (tagsFilter = []) => {
    return (note) => {
        if (tagsFilter.length === 0) {
            return true;
        }

        const noteTags = isArray(get(note, 'tags', [])) ? note.tags : [];

        return noteTags.some(noteTag => tagsFilter.indexOf(noteTag) >= 0);
    }
};
