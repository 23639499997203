import React, {useEffect, useState} from 'react';

import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';

import NewNoteIconButton from './components/note/NewNoteIconButton';
import NoteDialog from './components/note/NoteDialog';
import ConfirmationDialog from 'components/utils/ConfirmationDialog';
import CompletedDatesDialog from 'components/utils/CompletedDatesDialog';

import * as noteGql from 'gql/note';

import Inbox from 'pages/Inbox';
import Assistant from 'pages/Assistant';
import CategoryAdd from 'pages/Categories/CategoryAdd';
import CategoryEdit from 'pages/Categories/CategoryEdit';
import Categories from 'pages/Categories/Categories';

import useInboxNotes from "selectors/useInboxNotes";
import MenuToggle from "components/MenuToggle";
import * as routes from 'utils/routes';
import Goals from "./pages/Goals/Goals";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";

const App = () => {
    // const {loading, error, data} = useQuery(noteGql.GET_NOTES);
    // const inboxNotes = useInboxNotes();

    const inboxNotes = useInboxNotes();

    // console.log('inboxNotes', inboxNotes);

    return (
        <>
            <AppBar position="relative">
                <Toolbar style={{display: 'flex'}}>
                    {/*<MenuToggle />*/}
                    <Tooltip title="Goals">
                        <IconButton color="inherit" to={'/goals'} component={Link}>
                            <CenterFocusWeakIcon />
                        </IconButton>
                    </Tooltip>
                    <div style={{flex: 1}}></div>
                    {/*<Typography variant="h6" color="inherit" noWrap style={{flex: 1}}>*/}
                    {/*    P*/}
                    {/*</Typography>*/}
                    <Tooltip title="Inbox">
                        <IconButton color="inherit" to={'/'} component={Link}>
                            <Badge badgeContent={inboxNotes.length} color="secondary">
                                <MoveToInboxIcon fontSize="large"/>
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Tasks">
                        <IconButton color="inherit" to={'/assistant'} component={Link}>
                            <ViewColumnIcon fontSize="large"/>
                        </IconButton>
                    </Tooltip>
                    <NewNoteIconButton/>
                </Toolbar>
            </AppBar>
            <main>
                <NoteDialog/>
                <ConfirmationDialog/>
                <CompletedDatesDialog/>
                <Switch>
                    <Route path="/" component={Inbox} strict={true} exact={true}/>
                    <Route path="/assistant" component={Assistant} strict={true} exact={true}/>
                    <Route path={routes.ROUTE_CATEGORIES_NEW} component={CategoryAdd} strict={true} exact={true}/>
                    <Route path={routes.ROUTE_CATEGORY} component={CategoryEdit} strict={true} exact={true}/>
                    <Route path={routes.ROUTE_CATEGORIES} component={Categories} strict={true} exact={true}/>
                    <Route path={routes.ROUTE_GOALS} component={Goals} strict={true} exact={true}/>
                    {/* Add your routes here */}
                    <Route render={() => <h1>Not Found</h1>}/>
                </Switch>
            </main>
        </>
    )
};
export default App;
